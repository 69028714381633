import React from 'react'
import Slider from 'react-slick'

const TreandyPost = () => {
    let settings = {
        dots: false,
        arrows: false,
        speed: 2000,
        slidesToShow: 4,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,

        initialSlide: 0,
        infinite: true,
        vertical: true,
        responsive: [
            {
                breakpoint: 1040,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 830,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    let trendyPost = [
        {
            img: '../../Assest/trendy1.jpg',
            title: '12 Mobile UX Design Trends For 2018',
            des: `Things move quickly in the mobile app universe. To succeed in the field of mobile UX design, 
            designers must have the foresight and prepare for new challenges around the corner`
        },
        {
            img: '../../Assest/trendy2.jpg',
            title: 'What a Disabled Squirrel Taught Me About Life, Work, and Love',
            des: `Why it helps to know what it’s like, and the exquisite beauty of empathy These
             days, the wood patio area just outside my kitchen door is a riot `
        }, {
            img: '../../Assest/trendy3.jpg',
            title: 'How to Become a Master Designer',
            des: `Many outsiders believe that designers are unicorns, gifted or special in some way. We have an innate ability to create gorgeous
             interfaces, a natural talent for matching complementary colors, an unexplainable intuition…`
        },
        {
            img: '../../Assest/trendy4.jpg',
            title: 'No boat bottomfish: Jetty fishing on the Washington Coast',
            des: `Cast from the rocks to bring home fresh fish tacos in this iconic spring and summer fishery. 
             Are you looking to go fishing and bring home deliciou`
        },
        {
            img: '../../Assest/trendy5.jpg',
            title: 'Becoming a Self-Driving Car & Machine Learning Engineer',
            des: `How I left my full-time job, studied at Udacity, and landed a job at BMW The past year has been quite a 
            journey for me. A year ago I left my full-time`
        },
        {
            img: '../../Assest/trendy6.jpg',
            title: 'This free course can teach you music',
            des: `A Berlin-based music software company, just released a free interactive music course that 
            runs right in your browser. One thing that`
        }
    ]
    return (
        <div className='my-4 '>
            <p className='fw-medium text-2xl flex items-center gap-2 '> <span className='p-[3px] bg-blue-500 rounded-full h-[12px] w-[1px] '></span>Trendy Post </p>
            <div className='flex min-h[40vh] row'>
                <div className="slider-container col-xl-6 ">
                    <Slider className='' {...settings} rtl={true} >
                        {
                            trendyPost.map((x, index) => {
                                return (
                                    <div className='p-3  cursor-pointer shadow hover:scale-[1.05] my-2 transition duration-500 rounded  '>
                                        <div className='flex items-center'>
                                            <img src={x.img} className='w-1/3 h-fit ' alt="Image1" />
                                            <div className=' w-2/3 p-2 ' >
                                                <p className='fw-medium text-sm '>{x.title.length < 30 ? x.title : x.title.slice(0, 30) + '...'} </p>
                                                <p className='text-xs  '>{x.des.length < 120 ? x.des : x.des.slice(0, 120) + '...'} </p>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                        }

                    </Slider>
                </div>
                <div className="slider-container d-none  d-xl-block col-xl-6  ">
                    <Slider className=' ' {...settings} >
                        {
                            trendyPost.map((x, index) => {
                                return (
                                    <div className='p-3 cursor-pointer shadow hover:scale-[1.05] my-2 transition duration-500 rounded  '>

                                        <div className='flex items-center'>
                                            <img src={x.img} className='w-1/3 h-fit ' alt="Image1" />
                                            <div className=' w-2/3 p-2 ' >
                                                <p className='fw-medium text-sm '>{x.title.length < 30 ? x.title : x.title.slice(0, 30) + '...'} </p>
                                                <p className='text-xs  '>{x.des.length < 120 ? x.des : x.des.slice(0, 120) + '...'} </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>

            </div>
        </div>
    )
}

export default TreandyPost