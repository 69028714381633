import React from 'react'
import Slider from 'react-slick'
import { courses } from '../../Context/Data'
import ParticularCard from '../HomeComponents/ParticularCard'

const OurOtherCourses = () => {
    const settings={
        dots: false,
        arrows: false,
        speed: 2000,
        slidesToShow: 4,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        responsive: [
            {
                breakpoint: 1040,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 830,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <div className='my-20 my-xl-5 flex xl:min-h-[90vh] '>
            <div className='m-auto container mx-auto col-xl-10'>
                <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>Our   </p>
                <p className='epilogue m-0 text-center text-4xl fw-bold'>Other Courses </p>
               <Slider {...settings} className='my-5'>
                    {
                        courses.map((x,index)=>{
                            return(
                              <div>
                                  <ParticularCard page='course' obj={x} />
                              </div>
                            )
                        })
                    }

                </Slider>


            </div>

        </div>
    )
}

export default OurOtherCourses