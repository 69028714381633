import React from 'react'
import NavBarCom from '../Component/HomeComponents/NavBarCom'
import LocationIcon from '../svg/LocationIcon'
import PhoneIcon from '../svg/PhoneIcon'
import MailIcon from '../svg/MailIcon'

const ContactPage = () => {
    const latitude = 12.92875;
    const longitude = 77.5853652;
    return (
        <div>
            {/* Form */}
            <div className='row container mx-auto rounded-3xl min-h-[75vh] mt-4 p-0 overflow-hidden border-sky-300 border-2 '>
                <section className='col-lg-6 relative p-0'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.6726300280257!2d77.5827849108863!3d12.928749987330034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15adfc868c21%3A0xfa8b6c9cbfe474ad!2sMerida%20-%20Software%20Company%20%26%20Digital%20Marketing%20Company!5e0!3m2!1sen!2sin!4v1715701223288!5m2!1sen!2sin" className='w-full h-full' 
                    
                        allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <a href='https://www.google.com/maps/place/Merida+-+Software+Company+%26+Digital+Marketing+Company/@12.92875,77.5847457,19.08z/data=!4m14!1m7!3m6!1s0x3bae15adfc868c21:0xfa8b6c9cbfe474ad!2sMerida+-+Software+Company+%26+Digital+Marketing+Company!8m2!3d12.92875!4d77.5853652!16s%2Fg%2F11qbh0hp7f!3m5!1s0x3bae15adfc868c21:0xfa8b6c9cbfe474ad!8m2!3d12.92875!4d77.5853652!16s%2Fg%2F11qbh0hp7f?entry=ttu'
                        target='_blank' className='p-2 absolute top-0 right-0 z-10 rounded-full'>
                        <img src={require('../Assest/map.png')} alt="Map" width={50} />
                    </a>


                </section>
                <section className='col-lg-6 flex p-5 inter bg-sky-100 items-center justify-center'>
                    <div className=''>
                        <p className='text-3xl fw-semibold inter'>Let's connect constellations</p>
                        <p className='text-slate-500 w-5/6 text-sm'>Let's align our constellations! Reach out and let the magic of collaboration illuminate our skies.</p>

                        {/* Form  */}
                        <div className=''>
                            <div className='flex gap-3 mb-3 flex-col sm:flex-row justify-between'>
                                <input type="text" placeholder='First name' className='rounded w-full border-2 outline-none border-sky-200 p-2' />
                                <input type="text" placeholder='Last name' className='rounded w-full border-2 border-sky-200 outline-none p-2' />
                            </div>
                            <input type="email" placeholder='Email' className='rounded w-full border-2 border-sky-200 outline-none p-2 mb-3' />
                            <input type="number" placeholder='Phone' className='rounded w-full noarror border-2 border-sky-200 outline-none p-2 mb-3' />
                            <textarea name="" id="" cols="30" rows="6" placeholder='Message' className='rounded resize-none w-full noarror border-2 border-sky-200 outline-none p-2 mb-3'></textarea>
                            <button className='grd rounded p-2 w-full my-1 text-white'>Send Message</button>
                        </div>
                    </div>
                </section>
            </div>
            {/* Cards */}
            <div className='container flex gap-3 my-20 justify-center sm:justify-between flex-wrap mx-auto '>
                <div className='w-[20rem] xl:w-[23rem] h-[10rem] flex gap-3 items-center shadow inter p-4 rounded-2xl'>
                    <div className='p-3 text-white bg-blue-700 rounded-full '>
                        <LocationIcon size='20' />
                    </div>
                    <div>
                        <p className='fw-semibold m-0 '> Address</p>
                        <p className='text-slate-500'> Skill Learning Academy, JayaNagar 4th block,
                            Banglore-560076 </p>
                    </div>
                </div>
                <div className='w-[20rem] xl:w-[23rem] h-[10rem] flex gap-3 items-center shadow inter p-4 rounded-2xl'>
                    <div className='p-3 text-white bg-blue-700 rounded-full '>
                        <PhoneIcon size='20' />
                    </div>
                    <div>
                        <p className='fw-semibold m-0 '> Phone</p>
                        <p className='text-slate-500 m-0'>+91 9876543212 </p>
                        <p className='text-slate-500'>+91 9876543212 </p>

                    </div>
                </div>
                <div className='w-[20rem] xl:w-[23rem] h-[10rem] flex gap-3 items-center shadow inter p-4 rounded-2xl'>
                    <div className='p-3 text-white bg-blue-700 rounded-full '>
                        <MailIcon size='20' />
                    </div>
                    <div>
                        <p className='fw-semibold m-0 '> E-Mail Address</p>
                        <p className='text-slate-500 text-xs xl:text-sm  '> centermanager@meridatechminds.com</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactPage