import React from 'react'

const HowYouJorney = () => {
    return (
        <div className='my-20 my-xl-5  d-none d-lg-block xl:min-h-[90vh] flex'>
            <div className='m-auto container mx-auto col-xl-10'>
                <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>How you  </p>
                <p className='epilogue m-0 text-center text-4xl fw-bold'>Start  Journey </p>

                <section className='container p-5 p-xl-2 '>
                    <div className='border-b-2 flex relative flex-wrap pe-5 justify-between border-blue-600 py-5  '>
                    <div className='w-[15rem] xl:w-[20rem] relative rounded p-3 border-2 border-opacity-70 border-slate-400 '>
                            <p className='text-xl fw-semibold'> <span className='txtgrd fw-semibold'>#1 </span> Assemble the right team </p>
                            <p className='text-slate-500'>We handle all aspects of vetting and
                                choosing the right team that you don't have the time, expertise, or desire to do.</p>
                            <hr className='absolute left-[50%] opacity-100  -translate-x-1/2 -bottom-14 border-2 w-6 rotate-90 border-blue-600' />
                        </div>
                        <div className='w-[15rem] xl:w-[20rem] relative rounded p-3 border-2 border-opacity-70 border-slate-400 '>
                            <p className='text-xl fw-semibold'> <span className='txtgrd fw-semibold'>#3 </span> Assemble the right team </p>
                            <p className='text-slate-500'>We handle all aspects of vetting and
                                choosing the right team that you don't have the time, expertise, or desire to do.</p>
                            <hr className='absolute left-[50%] opacity-100  -translate-x-1/2 -bottom-14 border-2 w-6 rotate-90 border-blue-600' />
                        </div>
                        <div className='w-[15rem] xl:w-[20rem] relative rounded p-3 border-2 border-opacity-70 border-slate-400 '>
                            <p className='text-xl fw-semibold'> <span className='txtgrd fw-semibold'>#5 </span> Assemble the right team </p>
                            <p className='text-slate-500'>We handle all aspects of vetting and
                                choosing the right team that you don't have the time, expertise, or desire to do.</p>
                            <hr className='absolute left-[50%] opacity-100  -translate-x-1/2 -bottom-14 border-2 w-6 rotate-90 border-blue-600' />
                        </div>
                        <p className='p-2 border-[7px] absolute -bottom-8 left-0 bg-blue-600 rounded-full '></p>
                    </div>
                    {/* Bottom */}
                    <div className=' flex relative flex-wrap justify-between ps-5  py-5  '>
                        <div className='w-[15rem] xl:w-[20rem]  relative rounded p-3 border-2 border-opacity-70 border-slate-400 '>
                            <p className='text-xl fw-semibold'> <span className='txtgrd fw-semibold'>#2 </span> Assemble the right team </p>
                            <p className='text-slate-500'>We handle all aspects of vetting and
                                choosing the right team that you don't have the time, expertise, or desire to do.</p>
                            <hr className='absolute left-[50%] opacity-100  -translate-x-1/2 -top-14 border-2 w-6 rotate-90 border-blue-600' />
                        </div>
                        <div className='w-[15rem] xl:w-[20rem]  relative rounded p-3 border-2 border-opacity-70 border-slate-400 '>
                            <p className='text-xl fw-semibold'> <span className='txtgrd fw-semibold'>#4 </span> Assemble the right team </p>
                            <p className='text-slate-500'>We handle all aspects of vetting and
                                choosing the right team that you don't have the time, expertise, or desire to do.</p>
                            <hr className='absolute left-[50%] opacity-100  -translate-x-1/2 -top-14 border-2 w-6 rotate-90 border-blue-600' />
                        </div>
                        <div className='w-[15rem] xl:w-[20rem]  relative rounded p-3 border-2 border-opacity-70 border-slate-400 '>
                            <p className='text-xl fw-semibold'> <span className='txtgrd fw-semibold'>#6 </span> Assemble the right team </p>
                            <p className='text-slate-500'>We handle all aspects of vetting and
                                choosing the right team that you don't have the time, expertise, or desire to do.</p>
                            <hr className='absolute left-[50%] opacity-100  -translate-x-1/2 -top-14 border-2 w-6 rotate-90 border-blue-600' />
                        </div>
                        <img src={require('../../Assest/Trophy_perspective_matte.png')} alt='Trophy' width={100} className='p-2 absolute -top-12 -right-20 rounded-full '/>
                    </div>
                </section>
            </div>

        </div>
    )
}

export default HowYouJorney