import React, { useEffect, useState } from 'react'
import NavBarCom from '../Component/HomeComponents/NavBarCom'
import ReactPaginate from 'react-paginate'
import { courses } from '../Context/Data'
import ParticularCard from '../Component/HomeComponents/ParticularCard'
import FilterSource from '../Component/FilterSource'
import FilterIcon from '../svg/FilterIcon'
import { Offcanvas } from 'react-bootstrap'

const AllCoursePage = () => {
    let [data, setdata] = useState(courses != undefined ? [...courses] : [])

    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setcurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [showOffcanvas, setOffcanvas] = useState(false)
    const itemsPerPage = 9;
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setcurrentItems(data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, data])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [data])
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
        window.scroll(0, 0)
    };
    return (
        <div>
            <div className='bgclr2  '>
            </div>
            <div className='flex container my-3 col-xl-10 mx-auto mb-4 flex-wrap px-4 items-center justify-between'>
                <p className='m-0 ' >Showing
                    {itemOffset + 1}-{itemOffset + currentItems.length} of {data.length} results</p>

                <div className='flex ms-auto gap-2 items-center'>
                    <p className='m-0  '>Sort By :</p>
                    <select name="" id="" className='outline-none border-slate-400 border-1 p-2 rounded' >
                        <option value="">select</option>
                        {
                            courses && [...new Set(courses.map((obj) => obj.category))].map((x, index) => {
                                console.log(x);
                                return (
                                    <option value={x}> {x}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div className='container col-xl-10 flex mx-auto'>

                <div className=''>



                    <div className='flex flex-wrap gap-3 mx-auto justify-center sm:justify-between '>
                        {
                            currentItems && currentItems.map((x, index) => {
                                return (
                                    <ParticularCard page='all' obj={x} key={index} />
                                )
                            })
                        }
                    </div>
                </div>

            </div>

            <ReactPaginate
                breakLabel="..."
                nextLabel={null}
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={pageCount}
                previousLabel={null}
                containerClassName='pagination'
                pageLinkClassName='page-num'
                previousLinkClassName='page-num'
                nextLinkClassName='page-num'
                activeLinkClassName='active'
            />
        </div>
    )
}

export default AllCoursePage