import React, { useState } from 'react'
import PlusIcon from '../../svg/PlusIcon'

const Coursecurriculum = () => {
    const [selectedSubject, setSelectSubject] = useState()
    const subject = [
        {
            name: 'JavaScript',
            point: [
                'Texting',
                'Text formatting Tags',
                'HTML Structures - List, Tables',
                'HTML Forms',
                'GET VS POST',
                'Fieldset and Legend Tag',
                'HTML5 elements',
                'Audio, Video tags',
                'Types of CSS'
            ]
        },
        {
            name: 'Spring',
            point: [
                'Texting',
                'Text formatting Tags',
                'HTML Structures - List, Tables',
                'HTML Forms',
                'GET VS POST',
                'Fieldset and Legend Tag',
                'HTML5 elements',
                'Audio, Video tags',
                'Types of CSS'
            ]
        },
        {
            name: 'Java',
            point: [
                'Texting',
                'Text formatting Tags',
                'HTML Structures - List, Tables',
                'HTML Forms',
                'GET VS POST',
                'Fieldset and Legend Tag',
                'HTML5 elements',
                'Audio, Video tags',
                'Types of CSS'
            ]
        },
        {
            name: 'React',
            point: [
                'Texting',
                'Text formatting Tags',
                'HTML Structures - List, Tables',
                'HTML Forms',
                'GET VS POST',
                'Fieldset and Legend Tag',
                'HTML5 elements',
                'Audio, Video tags',
                'Types of CSS'
            ]
        }
    ]
    return (
        <div className='rounded bg-white welcomeanimation p-3 min-h-[20vh] shadow border-1 border-slate-500 border-opacity-35 '>
            {subject && subject.map((x, index) => {
                return (

                    <section className={` bg-sky-200 rounded bg-opacity-25 my-2`}>
                        <button onClick={() => setSelectSubject((prev) => {
                            if (prev == x.name) {
                                return ''
                            }
                            else
                                return x.name
                        })}
                            className={`p-3 flex items-center ${x.name == selectedSubject ? 'text-blue-500' : ''} justify-between rounded 
                             border-2 bgclr border-sky-300 border-opacity-35 w-full text-2xl fw-semibold sm:fw-bold text-start `} >
                            {x.name}
                            {x.name != selectedSubject && <div className='text-sky-300 '><PlusIcon size={30} /></div> }
                        </button>
                        <div className={`${x.name == selectedSubject ? '' : 'hidden'} transi p-3 px-4  `} >
                            <ul className='list-disc flex flex-wrap  text-slate-400 text-lg '>
                                {
                                    x.point.map((x) => (
                                        <li className='w-1/2'>{x} </li>
                                    ))
                                }
                            </ul>

                        </div>
                    </section>
                )
            })
            }


        </div>
    )
}

export default Coursecurriculum