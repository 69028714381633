import React from 'react'
import ProfileFill from '../../svg/ProfileFill'
import LocationFill from '../../svg/LocationFill'
import ServerFill from '../../svg/ServerFill'

const CourseBanner = (props) => {
    const { sub1, sub2, sub3, sub4 } = props
    return (
        <div style={{ backgroundImage: 'url(../../Assest/CourseBanner.png)', }} className='min-h-[50vh] xl:min-h-[90vh] '>
            <div
                className='min-h-[50vh] xl:min-h-[68vh] overflow-hidden relative flex'>
                <div className='m-auto'>
                    <div className='flex gap-2 items-center'>
                        <h4 className='text-5xl fw-semibold lg:text-7xl '> A new ERA </h4>
                        <img width={25} className=' h-fit' src={require('../../Assest/greenEcplise.png')} alt="GreenEcplise" />
                    </div>
                    <div className='flex gap-2 items-center'>
                        <img width={45} className=' h-fit' src={require('../../Assest/twoEcplise.png')} alt="GreenEcplise" />
                        <h4 className='text-5xl fw-semibold lg:text-7xl '>Experience </h4>
                    </div>
                </div>
                {/* Images of the Subjects */}
                <img className='w-[90px] h-[90px] absolute moving3 top-10 right-12 sm:top-20 sm:right-32 ' src={require('../../Assest/react.png')} alt="Course" />
                <img className='w-[90px] h-[90px] absolute moving1 top-20 left-12 sm:top-32 sm:left-24 ' src={require('../../Assest/python.png')} alt="Course" />
                <img className='w-[90px] h-[90px] absolute moving2 bottom-20 left-5 sm:bottom-24 sm:left-48 ' src={require('../../Assest/java.png')} alt="Course" />
                <img className='w-[90px] h-[90px] absolute moving bottom-5 right-20 sm:bottom-12 sm:right-72  ' src={require('../../Assest/javascript.png')} alt="Course" />

            </div>
            <div className='container p-3 p-sm-0 col-sm-10 rounded-xl mx-auto gap-3 gap-sm-0 justify-around min-h-[8rem] 
            flex-wrap flex items-center bg-opacity-85 shadow bg-white  '>
                <section className='flex gap-3 w-5/12 sm:w-1/4 sm:justify-center items-center border-e-2 '>
                    <div className='p-3 rounded-full h-fit text-blue-500 bg-slate-400 bg-opacity-25 w-fit '>
                        <ProfileFill size={35} />
                    </div>
                    <p className='fw-bold text-lg sm:text-xl m-0'>90+ <span className='text-slate-400 fw-normal block'>Users</span> </p>
                </section>
                <section className='flex gap-3 w-5/12 sm:w-1/4 justify-center items-center sm:border-e-2 '>
                    <div className='p-3 rounded-full h-fit text-blue-500 bg-slate-400 bg-opacity-25 w-fit '>
                        <ProfileFill size={35} />
                    </div>
                    <p className='fw-bold  text-lg sm:text-xl m-0'>90+ <span className='text-slate-400 fw-normal block'>Users</span> </p>
                </section>
                <section className='flex gap-3 w-5/12 sm:w-1/4 justify-center items-center border-e-2 '>
                    <div className='p-3 rounded-full h-fit text-blue-500 bg-slate-400 bg-opacity-25 w-fit '>
                        <LocationFill size={35} />
                    </div>
                    <p className='fw-bold  text-lg sm:text-xl m-0'>30+ <span className='text-slate-400 fw-normal block'>Locations</span> </p>
                </section>
                <section className='flex gap-3 w-5/12 sm:w-1/4 justify-center items-center '>
                    <div className='p-3 rounded-full h-fit text-blue-500 bg-slate-400 bg-opacity-25 w-fit '>
                        <ServerFill size={35} />
                    </div>
                    <p className='fw-bold  text-lg sm:text-xl m-0'>50+ <span className='text-slate-400 fw-normal block'>Server</span> </p>
                </section>
            </div>

        </div>
    )
}

export default CourseBanner