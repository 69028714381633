
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import StoreProvider from './Context/StoreProvider';
import LandingPage from './Pages/LandingPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Component/component.css'
import ContactPage from './Pages/ContactPage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from './Component/Footer';
import AllCoursePage from './Pages/AllCoursePage';
import ParticularCard from './Component/HomeComponents/ParticularCard';
import ParticularCourse from './Pages/ParticularCourse';
import NavBarCom from './Component/HomeComponents/NavBarCom';
import Blog from './Pages/Blog';
import ThreeD from './Pages/ThreeD';
import ParticularBlog from './Pages/ParticularBlog';
import WhySLA from './Pages/WhySLA';
import './Component/animation.css'
function App() {
  return (
    <>
      <BrowserRouter>
        <StoreProvider>
          <NavBarCom/>
          <Routes>
            <Route path='/*' element={<LandingPage/>} />
            <Route path='/contact' element={<ContactPage/>}/>
            <Route path='/allCourse' element={<AllCoursePage/>}/>
            <Route path='/course/:slug' element={<ParticularCourse/>}/>
            <Route path='/blogs/*' element={<Blog/>}/>
            <Route path='/blogs/:slug' element={<ParticularBlog/>}/>

            <Route path='/whysla' element={<WhySLA/>}/>
          </Routes>
          <Footer/>


        </StoreProvider>




      </BrowserRouter>

    </>
  );
}

export default App;
