import React from 'react'
import CalenderIcon from '../../svg/CalenderIcon'

const PopularPost = () => {
  return (
    <div className='rounded inter overflow-hidden my-4 '>
        <p className='text-white mb-0 rounded-t p-3 fw-semibold bg-blue-500 '> Popular Post </p>
        <div  style={{backgroundColor:'rgb(247,247,249)'}} className='rounded-b  bg-opacity-60 p-3 '>
            {
                [1,2,3].map((x)=>{
                    return(
                        <div className='flex my-2 '>
                            <img src={require('../../Assest/Blog1.png')} className='object-cover h-[70px] rounded ' width='80px' alt="Blog Imaeg" />
                            <div className='px-3'>
                                <p className='text-slate-600 flex gap-2 text-xs items-center'><span className='text-blue-600'><CalenderIcon size={12}/> </span> April 13,2024 </p>
                                <p className='text-xs text-black opacity-100 fw-medium '>The Right way to Learning Path for Your </p>
                            </div>

                        </div>
                    )
                })
            }

        </div>


    </div>
  )
}

export default PopularPost