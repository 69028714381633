import React, { useEffect, useState } from 'react'
import PlusIcon from '../../svg/PlusIcon'
import { instructor, student } from '../../Utils/Image'

const FAQ = (props) => {
    let { data } = props
    let [selectedFaq, setSelectedFAQ] = useState()
    let [faq, setFaq] = useState()
    useEffect(() => {
        if (data) {
            setFaq(data)
        }
    }, [data])
    return (
        <section id='faq' className='my-5 flex xl:min-h[90vh] my-xl-2 '>
            <div className='m-auto container col-xl-10   '>
                <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>Frequently</p>
                <p className='epilogue m-0 text-center text-4xl fw-bold'>Asked Questions </p>
                <div className='my-5'>
                    {
                        faq && faq.map((x, index) => {
                            return (
                                <section key={index} className={` bg-sky-200 rounded bg-opacity-25 my-2`}>
                                    <button onClick={() => {
                                        if (selectedFaq == index) {
                                            setSelectedFAQ(-1)
                                        } else {
                                            setSelectedFAQ(index)
                                        }
                                    }}
                                        className={`p-3 flex items-center ${index == selectedFaq ? 'text-blue-500' : ''} justify-between rounded 
                             border-2 bgclr border-sky-300 border-opacity-35 w-full text-xl fw-semibold sm:fw-bold text-start `} >
                                        {x.qn}
                                        {index != selectedFaq && <div className=' '><PlusIcon size={30} /></div>}
                                    </button>
                                    <div className={`${index == selectedFaq ? '' : 'hidden'} transi p-3 px-4  `} >
                                        <ul className='list-disc flex flex-wrap  text-slate-900 text-lg '>
                                            {
                                                x.ans
                                            }
                                        </ul>
                                    </div>
                                </section>
                            )
                        })
                    }
                </div>
                {/* Cards */}
                <div className='flex gap-3 justify-center poppins flex-wrap lg:justify-between '>
                    <div className='w-[28rem] xl:w-[35rem] flex items-center gap-2 rounded border-1 overflow-hidden h-[15rem] border-slate-400 bg-sky-200 bg-opacity-50  '>
                        <img src={instructor} alt="Instructor" width={213} className=' ' />
                        <div>
                            <p className='text-slate-900 text-xl fw-semibold'>Become a Instructor </p>
                            <p className='text-slate-500 text-xs'>To take a trivial example, which of us undertakes
                                physical exercise yes is this happen here.</p>
                            <button className='uppercase grd bclr2 px-3 p-2 rounded text-white'>Contact us </button>
                        </div>
                    </div>
                    <div className='w-[28rem] xl:w-[35rem] flex items-center gap-2 rounded border-1 overflow-hidden h-[15rem] border-slate-400 bg-sky-200 bg-opacity-50  '>
                        <img src={student} alt="Instructor" width={245} />
                        <div>
                            <p className='text-slate-900 text-xl fw-semibold'>Become a Student </p>
                            <p className='text-slate-500 text-xs'>To take a trivial example, which of us undertakes
                                physical exercise yes is this happen here.</p>
                            <button className='uppercase grd bclr2 px-3 p-2 rounded text-white'>Apply now </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQ