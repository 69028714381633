import React from 'react'
import { scollerCup } from '../../Utils/Image'
import ScholarCap from '../../svg/ScholarCap'

const WhoCanLearn = () => {
    return (
        <div className='container mx-auto my-5 col-xl-10'>
            <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>Who </p>
            <p className='epilogue m-0 text-center text-4xl fw-bold'>Can Learn  </p>
            <section className='my-5 flex flex-wrap justify-around gap-3 gap-lg-4 gap-xl-0 xl:justify-between '>
                <div style={{
                    boxShadow: '8px 8px 1px rgb(202,228,233)',
                    backgroundColor: 'rgb(241,253,255)',
                    borderColor: 'rgb(202,228,233)'
                }} className='rounded-2xl border-2 relative w-[21rem] lg:w-[24rem] p-4 px-5 h-[12rem] '>
                    <img className='absolute right-5' width={50} src={require('../../Assest/starTinkle.png')} alt="Star Tinkle" />
                    <div className='flex items-center gap-2'>
                        <p className='text-white bg-teal-500 p-3 m-0 rounded-full w-fit '> <ScholarCap size={30} /> </p>
                        <p className='m-0 text-lg fw-bold inter '> Expert Tutors </p>
                    </div>
                    <p className='inter mt-3 text-sm'>when an unknown printer took a galley offe
                        type and scrambled makes.</p>
                </div>
                {/* Card 2 */}
                <div style={{
                    boxShadow: '8px 8px 1px rgb(218,214,241)',
                    backgroundColor: 'rgb(238,234,255)',
                    borderColor: 'rgb(218,214,241)'
                }} className='rounded-2xl border-2 relative w-[21rem] lg:w-[24rem] p-4 px-5 h-[12rem] '>
                    <img className='absolute right-5' width={50} src={require('../../Assest/starTinkle.png')} alt="Star Tinkle" />
                    <div className='flex items-center gap-2'>
                        <p className='text-white bg-violet-500 p-3 m-0 rounded-full w-fit '> <ScholarCap size={30} /> </p>
                        <p className='m-0 text-lg fw-bold inter '> Expective Courses </p>
                    </div>
                    <p className='inter mt-3 text-sm'>when an unknown printer took a galley offe
                        type and scrambled makes.</p>
                </div>
                {/* Card 3 */}
                <div style={{
                    boxShadow: '8px 8px 1px rgb(230,222,203)',
                    backgroundColor: 'rgb(255,247,226)',
                    borderColor: 'rgb(230,222,203)'
                }} className='rounded-2xl border-2 relative w-[21rem] lg:w-[24rem] p-4 px-5 h-[12rem] '>
                    <img className='absolute right-5' width={50} src={require('../../Assest/starTinkle.png')} alt="Star Tinkle" />
                    <div className='flex items-center gap-2'>
                        <p className='text-white bg-amber-500 p-3 m-0 rounded-full w-fit '> <ScholarCap size={30} /> </p>
                        <p className='m-0 text-lg fw-bold inter '> Earn Certificate </p>
                    </div>
                    <p className='inter mt-3 text-sm'>when an unknown printer took a galley offe
                        type and scrambled makes.</p>
                </div>
            </section>

        </div>
    )
}

export default WhoCanLearn