import React from 'react'

const BlogComment = () => {
    return (
        <div style={{ backgroundColor: 'rgb(247,247,250)' }}
            className=' my-2 rounded-xl p-4 '>
            <h4 className='fw-semibold mb-3 '>Post a Comment </h4>
            <p>Your email address will not be published. Required fields are marked. </p>
            <textarea name="" id="" className='rounded-xl w-full outline-none p-2 border-1 bclr  ' rows={7} > </textarea>
            <div className='flex flex-wrap gap-3 gap-sm-0 justify-between'>
                <input type="text" className='border-1 w-64 outline-none bclr rounded p-2' placeholder='Name' />
                <input type="text" className='border-1 w-64 outline-none bclr rounded p-2' placeholder='Email' />
                <input type="text" className='border-1 w-64 outline-none bclr rounded p-2' placeholder='Website' />
            </div>
             <div className='flex gap-2 my-3'>
                <button className='p-[10px] h-fit border-slate-950 rounded-sm border-1'> </button>
                <p className='mb-0  '>Save my name, email, and website in this browser for the next time I comment. </p>
             </div>
             <button className='grd p-2 px-3 rounded bclr2 border-1 uppercase text-white '> post comments </button>
        </div>
    )
}

export default BlogComment