import React from 'react'
import { bubbltIcon, catIcon, facebookIcon, githubIcon, instagram, sendIcon } from '../Utils/Image'

const Footer = () => {
    return (
        <div className='footer text-white ubuntu ' >
            <div className='min-h-[40vh] flex '>
                <div className='m-auto container items-center mx-auto row flex'>
                    <div className='col-sm-6 col-lg-3 h-fit'>
                        <img src={require('../Assest/Merida_white.png')} alt="Merida Logo" />

                    </div>
                    <div className='col-sm-6 col-lg-3 '>
                        <h4 className='text-2xl my-3'>Use Cases</h4>
                        <button className='fw-light block'>Web-desingers </button>
                        <button className='fw-light block my-3'>Marketers </button>
                        <button className='fw-light block my-3'>Small Business </button>
                        <button className='fw-light block my-3'>Website Builder </button>
                        <button className='fw-light block h-[20px] my-3'>  </button>

                    </div>
                    <div className='col-sm-6 col-lg-3'>
                        <h4 className='text-2xl my-3'>Company</h4>
                        <button className='fw-light text-lg block'>About Us </button>
                        <button className='fw-light block my-3'>Careers </button>
                        <button className='fw-light block my-3'>FAQs </button>
                        <button className='fw-light block my-3'>Teams </button>
                        <button className='fw-light block my-3'>Contact Us </button>
                    </div>
                    <div className='col-sm-6 col-lg-3'>
                        <h4 className='text-2xl my-3'>Let's do it!</h4>
                        <div className='flex gap-3 '>
                            <img src={facebookIcon} className=' ' width={13} alt="FaceBook icon" />
                            <img src={catIcon} className='h-fit' width={20} alt="FaceBook icon" />
                            <img src={githubIcon} className='h-fit' width={20} alt="FaceBook icon" />
                            <img src={sendIcon} className='h-fit' width={20} alt="FaceBook icon" />
                            <img src={instagram} className='h-fit' width={20} alt="FaceBook icon" />
                            <img src={bubbltIcon} className='h-fit' width={20} alt="FaceBook icon" />




                        </div>
                        <h4 className='text-2xl my-3'>Join our Whatsapp</h4>
                        <p className=''>Subscribe to stay tuned for new web design and latest updates. Let's do it! </p>
                        <div className='flex gap-1'>
                            <input type="number" className='text-slate-500 outline-none p-2 w-44 sm:w-full ' 
                            placeholder='Enter the Phone number ' />
                            <button className='p-2 px-3 w-fit text-white bg-sky-300'>Join</button>
                        </div>

 

                    </div>



                </div>

            </div>
            <hr className='border-white border-2 ' />
            <div className='min-h-[20px] row container mx-auto '>
                <div className='col-lg-6 py-2 py-sm-0 flex flex-wrap gap-2 justify-around'>
                    <p>Privacy Policy</p>
                    <p>Terms of Use</p>
                    <p>Sales and Refunds </p>
                    <p>Legal</p>
                    <p>Site Map</p>
                </div>
                <div className='col-lg-6 py-2 py-sm-0'>
                    <p className='w-11/12 sm:w-4/6 mx-auto '>Skilllearningacademy © 2024, made for society with ❤️ by
                        Merida Tech Minds(OPC) Pvt. Ltd</p>

                </div>

            </div>

        </div>
    )
}

export default Footer