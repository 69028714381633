import React from 'react'
import ArrowDown from '../../svg/ArrowDown'

const CourseDescription = (props) => {
    let { data } = props
    return (
        data && 
        <div className='rounded inter bg-white poppins welcomeanimation p-2 p-sm-4 px-4 px-sm-5  lg:max-h-[88vh] xl:max-h-[150vh] overflow-y-scroll scrollbar shadow border-1 border-slate-500 border-opacity-35 '>
            <h4 className=' fw-bold mb-4'>Course Description</h4>
            {
                data.overview && data.overview.map((x, index) => (
                    <p className='text-slate-500 mt-3  '> {x}
                    </p>
                ))
            }

            <h4 className='inter fw-bold mt-3 mb-4' >Who is this course for?</h4>

            {/* Points */}
            {
                data.who && data.who.map((x) => (
                    <p className='flex m-0 items-start fw-semibold my-2 gap-2'>
                        <span className={` p-1 shadow ${data.who.length > 1} bg-blue-500 rounded-full text-white  -rotate-90`}><ArrowDown size={12} />
                        </span>{x}</p>
                ))
            }
            <h4 className='inter fw-bold mt-2 mb-4' >Why take this course?</h4>

            {/* Description */}
            {
                data.why && data.why.map((x) => (
                    <p className='text-slate-500 my-2  text-sm'>
                        {x}
                    </p>
                ))
            }
            {/* OutCome */}
            <h4 className='inter fw-bold mt-2 mb-3' >Outcome of the course</h4>
            {
                data.outcome && data.outcome.map((x) => (
                    <p className='text-slate-500 my-3 text-sm '>{x} </p>
                ))
            }
            {/* Outcome 2 */}
            {
                data.outcome2 && data.outcome2.map((x) => (
                    <div>
                        <h6> {x.heading} </h6>
                        {
                            x.point.map((y) => (
                                <p className='text-slate-500 text-sm '> {y} </p>
                            ))
                        }
                    </div>
                ))
            }
            {/* JOB */}
            <h4 className='inter fw-bold mt-2 mb-3' >Job Opportunities </h4>
            {
                data.job && data.job.map((x, index) => (
                    <p className='inter text-sm sm:text-base'> <span className='fw-semibold ' >{x.heading} </span> {x.para} </p>
                ))
            }
            {/* Different Designation */}
            <h4 className='inter fw-bold mt-2 mb-3' >Different Designations  </h4>
            {
                data.desgn && data.desgn.map((x) => (
                    <p className='inter text-sm sm:text-base'> <span className='fw-semibold ' >{x.heading} </span> {x.para} </p>
                ))
            }
            {/* Salary structure */}
            <h4 className='inter fw-bold mt-2 mb-3' >Salary Structure </h4>
            {
                data.sal && data.sal.map((x) => (
                    <p className='inter text-sm sm:text-base '> <span className='fw-semibold ' >{x.heading} </span> {x.para} </p>

                ))

            }
        </div>
    )
}

export default CourseDescription