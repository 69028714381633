import React from 'react'

const OurBlog = () => {

    return (
        <div className='min-h-[50vh] xl:min-h-[90vh] flex '>
            <div className='m-auto container col-10   '>
                <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>Our </p>
                <p className='epilogue text-center text-4xl fw-bold'>Blogs </p>
                <div className='flex flex-wrap gap-2 justify-between my-4'>
                    {
                        [1, 2, 3].map((x, index) => {
                            return (
                                <div className='rounded-2xl border-2 bg-sky-200 bg-opacity-30 border-sky-200 border-opacity-80 w-[22rem] '>
                                    <img src={require('../../Assest/Blog1.png')} alt="Blog 1 " className='rounded-xl' />
                                    <div className=' py-2 '>
                                        <p className='text-sky-500 bg-slate-400 bg-opacity-30 w-fit mx-auto p-1 px-2 text-sm rounded-full '>Development </p>
                                        <p className=' text-center px-2 fw-semibold inter'>Turn Emails & Attachments Into To-Dos with Apps </p>
                                        <hr className='border-dotted m-0' />
                                        <p className='text-center text-sm m-1'> May 2,2022 | Nattasha </p>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>


            </div>

        </div>
    )
}

export default OurBlog