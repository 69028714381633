import React from 'react'
import Playbutton from '../../svg/Playbutton'
import CalenderIcon from '../../svg/CalenderIcon'
import { useNavigate } from 'react-router-dom'

const ParticularCard = ({ obj, page }) => {
    let navigate = useNavigate()
    return (
        <div className={`${page == 'all' ? 'w-[17rem]  ' : page == 'course' ? 'w-[17rem] mx-auto ' : 'w-[18rem] xl:w-[22rem] h-[25rem] xl:h-[27rem]'}  border-2 border-slate-500 border-opacity-30 
        relative p-[12px] 
         rounded-xl shadow-violet-600 shadow `}>
            <img src={obj.img} className='rounded-2xl w-full' alt="Course image" />
            <section className=' px-sm-3 py-2'>
                <div className='flex justify-between items-center'>
                    <div className='flex items-center'>
                        {
                            obj.tags.map((x) => (
                                <p className='p-1  m-0 bg-slate-200 mx-1 h-fit text-xs rounded-full px-2'>{x} </p>
                            ))
                        }
                    </div>
                    <div className='flex gap-2 items-center'>
                        <img className='w-fit h-fit' src={require('../../Assest/star.png')} alt="Star Image" />
                        <p className='text-slate-500 text-sm m-0'> (4.8 Reviews)</p>
                    </div>
                </div>
                <p className='text-lg xl:text-xl  h-[50px] text-black fw-semibold my-2 mb-1'>{obj.title} </p>
                <p className='text-slate-500 text-sm xl:text-md m-0 '>
                    Wilson ipasom lorem is the best thing to do with this course
                </p>
                <hr className='m-0 my-2' />
                <button onClick={() => navigate(`/course/${obj.slug}`)} className='p-2 px-3 uppercase text-white fw-semibold letter-spacing3 rounded bclr1 grd2 text-sm flex 
                text-center justify-center w-5/6 mx-auto '> View details</button>
            </section>
            {obj.popular && <div className='absolute -top-1 -left-1 '>
                <img src={require('../../Assest/popularBand.png')} alt="Popular Band" />
                <p className='text-white text-md -rotate-45 absolute top-6 -left-1 inter uppercase '>Popular</p>

            </div>}

        </div>
    )
}

export default ParticularCard