import React from 'react'
import { useParams } from 'react-router-dom'
import LatestPost from '../Component/Blog/LatestPost'
import SearchIcon from '../svg/SearchIcon'
import BlogDetails from '../Component/Blog/BlogDetails'
import BlogComment from '../Component/Blog/BlogComment'
import ArrowDown from '../svg/ArrowDown'

const ParticularBlog = () => {
    let { slug } = useParams()

    return (
        <div className='container mx-auto col-xl-10 '>
            <section className='row my-5'>
                <div className='col-lg-8 col-xl-9 '>
                    <BlogDetails/>
                    <BlogComment/>


                </div>
                <div className='col-lg-4 col-xl-3 '>
                    <div className=' rounded w-full p-1 bg-slate-50'>
                        <div className='rounded flex w-full '>
                            <input type="text" className='w-full outline-none border-0 bg-transparent h-full rounded p-1' placeholder='Search' />
                            <button className='p-[10px] rounded text-blue-600'> <SearchIcon /> </button>
                        </div>
                    </div>
                    {/* Categories */}
                    <div  style={{ backgroundColor: 'rgb(247,247,249)' }} className='my-2 p-3 rounded '>
                        <p className='text-black text-lg fw-semibold '>Categories</p>
                        {
                            ['Art & Design' , 'Business', 'Data Science','Development','Investment'].map((x)=>(
                                <p className='flex text-slate-500 '>
                                    <span className='-rotate-90 text-violet-600 '><ArrowDown/> </span>
                                    {x}
                                 </p>
                            ))
                        }

                    </div>
                    <LatestPost />
                    <div style={{ backgroundColor: 'rgb(247,247,249)' }} className='my-2 p-3 rounded '>
                        <p className='text-black text-lg fw-semibold '>Tags</p>
                        <div className='flex gap-2 flex-wrap'>

                            {
                                ['Education', 'Training', 'Online', 'Learn', 'Course', 'LMS'].map((x) => {
                                    return (
                                        <p className='p-1 mb-0 px-2 text-violet-500 bg-white'>{x} </p>
                                    )
                                })
                            }

                        </div>
                    </div>

                </div>

            </section>

        </div>
    )
}

export default ParticularBlog