import React from 'react'
import Slider from 'react-slick';

const WhatWeAreProviding = () => {
    var settings2 = {
        dots: false,
        arrows: false,
        speed: 2000,
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        className: "center",
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='container my-5 col-xl-10 p-4  mx-auto  rounded-2xl overflow-hidden  shadow '>
            <div className=' rounded-2xl p-4 relative overflow-hidden '>
                <img src={require('../../Assest/Ellipse5.png')} alt="Ecplise" className='absolute  bottom-0 left-0' />

                <img className=' m-auto z-10 ' width={120} src={require('../../Assest/Trophy_perspective_matte.png')} alt="Logo1" />
                <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>What  </p>
                <p className='epilogue m-0 text-center text-4xl fw-bold'>We are providing </p>
                <hr className='w-5/6 mx-auto mt-2 ' />
                <div className="slider-container">

                    <Slider {...settings2} className=' xl:min-h-[60vh]   ' >
                        {
                            [1, 2, , 3, 4, 4, 4,].map((x, index) => {
                                return (
                                    <div className='mt-24 relative'>
                                        <img src={require('../../Assest/originalCertificate.png')} className='slider-item ' alt='certificate' />
                                        {/* <img className='absolute  right-[50%] -bottom-4 z-10 ' src={require('../../Assest/sealCertificate.png')} alt="Seal" /> */}
                                    </div>
                                )
                            })
                        }
                    </Slider>

                </div>
                <img src={require('../../Assest/Ellipse6.png')} alt="Ecplise" className='absolute d-none d-sm-block top-0 right-0' />


            </div>
        </div>
    )
}

export default WhatWeAreProviding