import React from 'react'
import Slider from 'react-slick';
import SliderLeft from '../../svg/SliderLeft';
import SliderRight from '../../svg/SliderRight';

const CompaniesTrustUs = () => {
  var settings = {
    // dots: false,

    speed: 500,
    slidesToShow: 4,
    // rtl: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,

    nextArrow: <SliderRight size={26} />,
    prevArrow: <SliderLeft size={26} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  let companies = ['../../Assest/Company1.png', '../../Assest/Company2.png', '../../Assest/Company3.png', '../../Assest/Company4.png', '../../Assest/Company5.png']
  return (
    <div className='container mx-auto my-auto py-3 flex flex-col min-h-[40vh] '>
      <div className='my-auto '>
        <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>These companies </p>
        <p className='epilogue text-center text-4xl fw-bold'>Trust Us </p>
        <div className={` rounded bclr1 p-3 py-4 border-2 bg-sky-100`}>

          <Slider {...settings} className='px-3'>
            {
              companies && companies.map((x, index) => {
                return (
                  <div className=' ' key={index}>
                    <img src={x} className='mx-auto' alt="Companies" />
                  </div>
                )
              })
            }
          </Slider>

        </div>


      </div>
    </div>
  )
}

export default CompaniesTrustUs