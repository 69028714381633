import React, { createContext, useContext } from 'react'
export const Store = createContext()
const StoreProvider = (props) => {


    let values={}
  return (
    <Store.Provider value={values}>
        {props.children}

    </Store.Provider>
  )
}

export default StoreProvider