import React from 'react'

const HowItWorks = () => {
    return (
        <div className='my-20 d-none d-sm-block '>
            <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>How </p>
            <p className='epilogue text-center text-4xl fw-bold'>It's works</p>
            {/* <div className='row container mx-auto min-h-[40vh] '>
                <div className='dottedBorder relative col-lg-6 w-full min-h-full'>
                    <p className='circle'></p>
                    <div className=''>
                    <img src={require('../../Assest/join.png')} className='mx-auto my-16' alt="Join image" width={150} />
                    <p className='circle'></p>
                    </div>
                </div>
                <div className='col-lg-6  '>
                </div>
            </div> */}
            <div className='row container mx-auto  '>
                {/* section starts */}
                <div className='dottedBorder relative p-0 col-sm-6 w-full min-h-full'>
                    <p className='circle'></p>
                    <h6 className='h-[30px] '></h6>
                    <div className='relative w-full '>
                        <img src={require('../../Assest/join.png')} className='mx-auto my-16' alt="Join image" width={150} />
                        <p className='circle top-[50%] transform -translate-y-3 '></p>
                    </div>
                    <h6 className='h-[30px] '></h6>

                    <div className='relative w-full  my-16 '>
                        <div className='p-3 rounded bclr border-2 w-4/6 bg-sky-100 border-sky-200 mx-auto '>
                            <p className='text-xl fw-semibold'><span>#2</span> Code Reviews</p>
                            <p className=''>Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells</p>
                        </div>
                        <p className='circle top-[50%] transform -translate-y-3 '></p>
                    </div>
                    <h6 className='h-[30px] '></h6>

                    <div className='relative w-full '>
                        <img src={require('../../Assest/Trophy_perspective_matte.png')} className='mx-auto my-16' alt="Join image" width={150} />
                        <p className='circle top-[50%] transform -translate-y-3 '></p>
                    </div>
                    <h6 className='h-[30px] '></h6>

                    <p className='circle m-0 bottom-0'></p>
                </div>
                <div className='col-sm-6 relative'>
                    <h6 className='h-[30px] '></h6>

                    <div className='p-3 rounded bclr border-2 my-16 w-4/6 bg-sky-100 border-sky-200 mx-auto '>
                        <p className='text-xl fw-semibold'><span>#1</span> Code Reviews</p>
                        <p className=''>Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells</p>
                    </div>
                    <h6 className='h-[30px] '></h6>

                    <img src={require('../../Assest/comptr.png')} alt="Computer logo" width={170} className='mx-auto' />
                    <h6 className='h-[30px] '></h6>
                    <h6 className='h-[30px] '></h6>
                    <h6 className='h-[30px] '></h6>
                    <div className='p-3 rounded bclr border-2 my-16 w-4/6 bg-sky-100 border-sky-200 mx-auto '>
                        <p className='text-xl fw-semibold'><span>#3</span> Code Reviews</p>
                        <p className=''>Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells</p>
                    </div>

                </div>
            </div>
            <img width={180} src={require('../../Assest/certificate.png')} alt="Certificate" className='mx-auto my-3' />

        </div>

    )
}

export default HowItWorks