import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { courses } from '../../Context/Data';
const Allpost = () => {
    let [data, setdata] = useState(courses != undefined ? [...courses] : [])

    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setcurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [showOffcanvas, setOffcanvas] = useState(false)
    const itemsPerPage = 4;
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setcurrentItems(data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, data])
    useEffect(() => {
        window.scroll(0, 0);
    }, [data])
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
        window.scroll(0, 0)
    };
    return (
        <div>
            <p className='fw-medium text-2xl flex items-center gap-2 '> <span className='p-[3px] bg-blue-500 rounded-full h-[12px] w-[1px] '></span> All Posts </p>
            {
                currentItems.map((x) => {
                    return (
                        <div className='flex flex-col sm:flex-row p-3  rounded shadow my-2'>
                            <img src={x.img} className=' sm:w-1/2 rounded h-[210px] object-cover ' alt="" />  
                            <div className='p-3 sm:w-1/2'>
                                <p className='  fw-semibold text-xl ' >12 Mobile UX Design Trends For 2018</p>
                              <p className=''>Things move quickly in the mobile app universe. To succeed in the field of mobile UX design, 
                                designers must have the foresight and prepare for new challenges around the corner</p>

                            </div>
                        </div>
                    )
                })
            }

            <ReactPaginate
                breakLabel="..."
                nextLabel={null}
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={pageCount}
                previousLabel={null}
                containerClassName='pagination'
                pageLinkClassName='page-num'
                previousLinkClassName='page-num'
                nextLinkClassName='page-num'
                activeLinkClassName='active'
            />
        </div>
    )
}

export default Allpost