import React from 'react'
import WhySlaContent from '../Component/WhySlaContent'

const WhySLA = () => {
    return (
        <div>
            <div className='container mx-auto py-5  '>
                <p className='epilogue uppercase letter-spacing2 text-center text-blue-600'>WHY</p>
                <p className='epilogue m-0 text-center text-4xl fw-bold'>Skill learning <span className='text-blue-600'>academy? </span> </p>
                <div className='relative d-none d-md-block py-32 my-12 poppins'>
                    <img src={require('../Assest/whySLA.png')} className='w-full ' alt="Why Sla" />
                    {/* 1 */}
                    <div className='absolute w-[220px] lg:w-[260px] xl:w-[300px] top-10 right-2 lg:top-5 lg:right-0 xl:top-0 xl:right-0'>
                        <p className='text-xl lg:text-2xl text-slate-950 text-opacity-80 mb-1 fw-bold ' >
                            <span className='text-3xl lg:text-4xl text-blue-600 '>01</span> Interview </p>
                        <p className='text-xs lg:text-sm xl:text-base '>Thousands Of Jobs Are Available Why Still All The Jobs Aren't Yet Filled </p>
                    </div>
                    {/* 2 */}
                    <div className='absolute w-[220px] lg:w-[260px] xl:w-[300px] top-24 right-[220px] lg:top-20 lg:right-[280px] xl:top-28 xl:right-[400px]'>
                        <p className='text-xl lg:text-2xl text-slate-950 text-opacity-80 mb-1 fw-bold ' >
                            <span className='text-3xl lg:text-4xl text-blue-600 '>01</span> Interview </p>
                        <p className='text-xs lg:text-sm xl:text-base '>Thousands Of Jobs Are Available Why Still All The Jobs Aren't Yet Filled </p>
                    </div>
                     {/* 3 */}
                     <div className='absolute w-[220px] lg:w-[260px] xl:w-[300px] top-40 left-[60px] lg:top-48 lg:left-[130px] xl:top-56 xl:left-[200px]'>
                        <p className='text-xl lg:text-2xl text-slate-950 text-opacity-80 mb-1 fw-bold ' >
                            <span className='text-3xl lg:text-4xl text-blue-600 '>01</span> Interview </p>
                        <p className='text-xs lg:text-sm xl:text-base '>Thousands Of Jobs Are Available Why Still All The Jobs Aren't Yet Filled </p>
                    </div>
                    {/* Bottom */}
                     {/* 1 */}
                     <div className='absolute w-[220px] lg:w-[260px] left-[10px] lg:bottom-16 lg:left-[150px] xl:w-[300px] xl:bottom-16 xl:left-[200px]'>
                        <p className='text-xl lg:text-2xl text-slate-950 text-opacity-80 mb-1 fw-bold ' >
                            <span className='text-3xl lg:text-4xl text-blue-600 '>01</span> Interview </p>
                        <p className='text-xs lg:text-sm xl:text-base'>Thousands Of Jobs Are Available Why Still All The Jobs Aren't Yet Filled </p>
                    </div>
                    {/* 2 */}
                    <div className='absolute w-[220px] lg:w-[260px] xl:w-[300px] bottom-28 left-[250px] lg:bottom-32 lg:left-[400px] xl:bottom-52 xl:left-[600px]'>
                        <p className='text-xl lg:text-2xl text-slate-950 text-opacity-80 mb-1 fw-bold ' >
                            <span className='text-3xl lg:text-4xl text-blue-600 '>01</span> Interview </p>
                        <p className='text-xs lg:text-sm xl:text-base'>Thousands Of Jobs Are Available Why Still All The Jobs Aren't Yet Filled </p>
                    </div>
                    {/* 3 */}
                    <div className='absolute w-[220px] lg:w-[260px] xl:w-[300px] bottom-40 right-0 lg:bottom-52 lg:right-[10px] xl:bottom-80 xl:right-[0px]'>
                        <p className='text-xl lg:text-2xl text-slate-950 text-opacity-80 mb-1 fw-bold ' >
                            <span className='text-3xl lg:text-4xl text-blue-600 '>01</span> Interview </p>
                        <p className='text-xs lg:text-sm xl:text-base'>Thousands Of Jobs Are Available Why Still All The Jobs Aren't Yet Filled </p>
                    </div>
                </div>
                {/* Mobile screen */}
                <div className='relative container d-md-none py-4 my-3 poppins'>
                    <img  src={require('../Assest/WHYSlaMobile.png')} className='w-[50vw] mx-auto ' alt="Why Sla" />
                    {/* 1 */}
                    <div className='absolute w-[120px] top-16 right-0 '>
                        <p className='text-lg text-slate-950 text-opacity-80 mb-1 fw-bold ' >
                            <span className='text-2xl lg:text-4xl text-blue-600 '>01</span> Interview </p>
                        <p style={{fontSize:'9px'}} className=''>Thousands Of Jobs Are Available Why Still All The Jobs Aren't Yet Filled </p>
                    </div>
                    {/* 2 */}
                    <div className='absolute w-[120px] top-36 left-3 '>
                        <p className='text-lg text-slate-950 text-opacity-80 mb-1 fw-bold ' >
                            <span className='text-2xl lg:text-4xl text-blue-600 '>01</span> Interview </p>
                        <p style={{fontSize:'9px'}} className=''>Thousands Of Jobs Are Available Why Still All The Jobs Aren't Yet Filled </p>
                    </div>
                     {/* 3 */}
                     <div className='absolute w-[120px] top-64 right-0 '>
                        <p className='text-lg text-slate-950 text-opacity-80 mb-1 fw-bold ' >
                            <span className='text-2xl lg:text-4xl text-blue-600 '>01</span> Interview </p>
                        <p style={{fontSize:'9px'}} className=''>Thousands Of Jobs Are Available Why Still All The Jobs Aren't Yet Filled </p>
                    </div>
                    {/* Bottom */}
                     {/* 1 */}
                     <div className='absolute w-[120px] bottom-10 left-0 '>
                        <p className='text-lg text-slate-950 text-opacity-80 mb-1 fw-bold ' >
                            <span className='text-2xl lg:text-4xl text-blue-600 '>01</span> Interview </p>
                        <p style={{fontSize:'9px'}} className=''>Thousands Of Jobs Are Available Why Still All The Jobs Aren't Yet Filled </p>
                    </div>
                    {/* 2 */}
                    <div className='absolute w-[120px] bottom-36 right-0 '>
                        <p className='text-lg text-slate-950 text-opacity-80 mb-1 fw-bold ' >
                            <span className='text-2xl lg:text-4xl text-blue-600 '>01</span> Interview </p>
                        <p style={{fontSize:'9px'}} className=''>Thousands Of Jobs Are Available Why Still All The Jobs Aren't Yet Filled </p>
                    </div>
                    {/* 3 */}
                    <div className='absolute w-[120px] bottom-64 left-0 '>
                        <p className='text-lg text-slate-950 text-opacity-80 mb-1 fw-bold ' >
                            <span className='text-2xl lg:text-4xl text-blue-600 '>01</span> Interview </p>
                        <p style={{fontSize:'9px'}} className=''>Thousands Of Jobs Are Available Why Still All The Jobs Aren't Yet Filled </p>
                    </div>
                </div>
            </div>
            {/* Content */}
            <WhySlaContent/>

        </div>
    )
}

export default WhySLA