import React, { useState } from 'react'
import { courses } from '../../Context/Data'
import { useNavigate } from 'react-router-dom'
import ScholarCap from '../../svg/ScholarCap'
import ArrowDown from '../../svg/ArrowDown'

const NavDrop = (props) => {
  let {setshow}=props
  let navigate = useNavigate()
  let [selectedCategory, setSelectedCategory] = useState('')
  let categories = new Set([...courses.map((x) => x.category)])
  console.log(categories);
  return (
    <div onMouseLeave={()=>setshow(false)}className='min-h-[20vh] z-10 absolute top-14 flex rounded bg-white  '>
      <div className='  lg:w-[320px] xl:w-[350px] z-1 shadow h-[500px] border-1 p-0 overflow-y-scroll scrollbar '>
        <div className='flex w-full p-3 justify-between '>
          <p className='mb-0 uppercase text-sm '> Course category</p>
          <button onClick={() => navigate('/allcourse')} className='text-sky-600 text-sm '>View All Courses </button>
        </div>
        {/* buttons */}
        {
          categories && [...categories].map((x, index) => (
            <button onClick={() =>{
              selectedCategory==x ? setSelectedCategory('') : setSelectedCategory(x)
            }} className={` border-s-4 flex items-center justify-between hover:bg-slate-50 fw-medium hover:text-sky-600 
            w-full text-start p-3  bclr2 ${selectedCategory==x ? ' bg-slate-50 text-sky-600':''}`}>
              <p className='mb-0 flex gap-2 items-center'> <span style={{ backgroundColor: 'rgb(31,203,227)' }} className='p-2 rounded-full text-white  '>
                <ScholarCap /> </span> {x}
              </p>
              <div className='-rotate-90 text-slate-400 '>
                <ArrowDown />
              </div>
            </button>
          ))
        }
      </div>
      {selectedCategory != '' && <div className='bg-white lg:w-[42vw] xl:w-[50vw]  overflow-y-scroll scrollbar    rounded-e-xl p-3 h-[500px]'>
        <h4>{selectedCategory} </h4>
        <hr />
        <div className='flex flex-wrap gap-3 justify-between'>
          {
            courses.filter((x) => x.category == selectedCategory).map((x) => (
              <div onClick={()=>{navigate(`/course/${x.slug}`);setshow(false) }} className='rounded-lg border-1 cursor-pointer h-fit w-2/5 p-2 '>
                <div style={{backgroundColor:'rgb(224,234,255)'}} className='p-2 relative rounded flex  '>
                  <p className='min-h-[42px] flex items-center mb-0 text-slate-800 text-opacity-80 fw-semibold w-4/5 mt-3 mt-xl-0'>{x.title}</p>
              {x.popular&&  <p className='mb-0 absolute top-0 right-0 bg-blue-600 text-white text-xs rounded-bl rounded-tr p-1 w-fit '>Popular </p>}
                </div>
                <p className='text-xs mt-2 mb-0'>
                  {x.overview&& x.overview[0].slice(0,50)+'....'}
                </p>

              </div>
            ))
          }
        </div>
      </div>}

    </div>
  )
}

export default NavDrop