import React from 'react'
import { Carousel } from 'react-bootstrap'
import NavBarCom from './NavBarCom'
import { ecplise1, ecplise2, homebannerpic } from '../../Utils/Image'
import ArrowDown from '../../svg/ArrowDown'
import ArrowRightUp from '../../svg/ArrowRightUp'
const HomeBanner = () => {
    const homeBanner = [
        {
            img: '../../Assest/homebanner1.png',
        },
        // {
        //     img: '../../Assest/BannerPic.png',
        // },
        {
            img: '../../Assest/homebanner2.png',
        }
    ]
    return (
        <div className='relative'>
            <img src={ecplise2} alt="Ecplise 3" className='absolute -bottom-20 left-0' />
            <img src={ecplise1} className='absolute top-0 right-0 ' alt="Ecplise" />
            <Carousel>
                {homeBanner.map((x) => {
                    return (
                        <Carousel.Item className=' ' interval={2500} >


                            <div className='row min-h-[100vh] lg:min-h-[70vh] relative z-0 container mx-auto'>

                                <div className='col-lg-6 lg:min-h-[65vh] xl:min-h-[88vh] flex items-center justify-center '>
                                    <div className=''>
                                        <p className='uppercase text-3xl lg:text-3xl letter-spacing '>Small Steps to </p>
                                        <span className='fw-semibold text-5xl sm:text-7xl lg:text-9xl uppercase tracking-widest'>Future</span>
                                        <p className='tracking-wide text-lg w-9/12 my-2 '>Thousands Of Jobs Are Avilable Why Still All The Jobs Aren't Yet Filled</p>
                                        <button className='p-2 px-3 rounded grd uppercase text-sm text-white fw-semibold my-5 flex gap-2'>Join now <ArrowRightUp /> </button>
                                    </div>
                                </div>
                                <div className='col-lg-6 lg:min-h-[65vh] xl:min-h-[88vh] flex items-center justify-center '>
                                    <div className=''>
                                        <img src={x.img} className='w-[430px]  ' alt="Home banner" />
                                        <div
                                            className='rounded p-3 bg2 flex justify-around gap-5 border-sky-400 border-1 w-fit '>
                                            <p className='m-0 text-sm fw-semibold '>1000 Hrs <span className='block fw-normal'>of Contents</span></p>
                                            <p className='m-0 text-sm fw-semibold '>900 + <span className='block fw-normal'>Enrolled</span></p>
                                            <p className='m-0 text-sm fw-semibold '>300 + <span className='block fw-normal'>Student's Placed</span></p>
                                            <p className='m-0 text-sm fw-semibold '>100 + <span className='block fw-normal'>of Courses </span></p>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </Carousel.Item>
                    )
                })
                }
            </Carousel>

        </div>
    )
}

export default HomeBanner