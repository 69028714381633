import React from 'react'
import { airbnbLogo, amazonLogo, bookMyShowLogo, fedExLogo, googleLogo, microsoftLogo, olaLogo, oyoLogo, walmartLogo } from '../../Utils/Image'

const OurKnowledgePartners = () => {
    return (
        <div className='xl:min-h-[90vh] flex my-20 '>
            <div className=' m-auto container mx-auto col-xl-10  '>
                <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>Our knowledge  </p>
                <p className='epilogue m-0 text-center text-4xl fw-bold'>Partners </p>
                <section className='flex flex-wrap gap-2 d-sm-none justify-between ' >
                    <img className='w-[11rem] h-fit ' src={airbnbLogo} alt="Air Bnb" />
                    <img className='w-[11rem] h-fit ' src={googleLogo} alt="Air Bnb" />
                    <img className='w-[11rem] ' src={bookMyShowLogo} alt="Book my logo" />
                    <img className='w-[11rem] ' src={microsoftLogo} alt="MicroSoft Logo" />
                    <img className='w-[11rem] ' src={fedExLogo} alt="FedEx Logo" />
                    <img className='w-[11rem] ' src={walmartLogo} alt="Walmart Logo" />

                    <img className='w-[11rem] h-fit    ' src={olaLogo} alt="Ola Logo" />
                    <img className='w-[11rem] h-fit' src={amazonLogo} alt="Amazon Bnb" />

                </section>
                <section className='flex flex-wrap d-none d-sm-flex my-5 justify-center items-center gap-2'>
                    <div className='w-fit h-fit'>
                        <img className='w-[10rem] h-fit ' src={airbnbLogo} alt="Air Bnb" />
                        <img className='w-[12rem] h-fit mt-3 d-lg-none    ' src={olaLogo} alt="Ola Logo" />
                        <img className='w-[10rem] h-fit my-3 d-lg-none ' src={amazonLogo} alt="Amazon Bnb" />



                    </div>
                    <div className='w-fit h-fit'>
                        <img className='w-[12rem] ' src={googleLogo} alt="Air Bnb" />
                        <img className='w-[12rem] ' src={bookMyShowLogo} alt="Book my logo" />
                        <img className='w-[12rem] d-lg-none' src={oyoLogo} alt="Oyo Logo" />

                    </div>
                    <div className='w-fit h-fit'>
                        <img className='w-[12rem] ' src={microsoftLogo} alt="MicroSoft Logo" />
                        <img className='w-[12rem] ' src={fedExLogo} alt="FedEx Logo" />
                        <img className='w-[12rem] ' src={walmartLogo} alt="Walmart Logo" />
                    </div>
                    <div className='w-fit h-fit'>
                        <img className='w-[12rem]  d-none d-lg-block' src={oyoLogo} alt="Oyo Logo" />
                        <img className='w-[12rem]  d-none d-lg-block' src={olaLogo} alt="Ola Logo" />

                    </div>
                    <div className='w-fit h-fit'>
                        <img className='w-[10rem] d-none d-lg-block' src={amazonLogo} alt="Amazon Bnb" />
                    </div>
                </section>


            </div>

        </div>
    )
}

export default OurKnowledgePartners