import React from 'react'
import { emailMarketing, learnAnything, learnWithExperts, onlineCertificate } from '../../Utils/Image'

const WhyChoose = () => {
    return (
        <div style={{ background: 'rgb(20,20,74)' }} className='text-white text-center my-20 '>
            <div className='container text-center text-white poppins mx-auto p-4 '>
                <p style={{ backgroundColor: 'rgb(88,81,225)' }} className='p-1 inter px-3 rounded-full mx-auto bg-violet-400 w-fit text-white'>Why Choose  </p>
                <p className='text-2xl lg:text-3xl fw-semibold '>
                    Python For Full Stack Development ?
                </p>
                <p className='text-slate-400 mx-auto text-xs '>Groove’s intuitive shared inbox makesteam members together <br />
                    organize, prioritize and.In this episode.</p>

                <section className='flex flex-wrap my-5 gap-2 justify-between '>
                    <div className='flex w-2/5 lg:w-1/5 flex-col  items-center'>
                        <img width={70} src={learnWithExperts} alt="Learn With Experts" />
                        <p className='text-md fw-semibold mt-3 mb-1'>Learn with Experts</p>
                        <p className='text-slate-400 text-sm'>Curate anding area share Pluralsight
                            content to reach your</p>
                    </div>
                    <div className='flex w-2/5 lg:w-1/5 flex-col items-center'>
                        <img width={70} src={learnAnything} alt="Learn With Experts" />
                        <p className='text-md fw-semibold mt-3 mb-1'>Learn Anything</p>
                        <p className='text-slate-400 text-sm'>Curate anding area share Pluralsight 
                            content to reach your</p>
                    </div>
                    <div className='flex flex-col  w-2/5 lg:w-1/5  items-center'>
                        <img width={70} src={onlineCertificate} alt="Learn With Experts" />
                        <p className='text-md fw-semibold mt-3 mb-1'>Get online Certificate </p>
                        <p className='text-slate-400 text-sm'>Curate anding area share Pluralsight 
                            content to reach your</p>
                    </div>
                    <div className='flex flex-col w-2/5 lg:w-1/5 t items-center'>
                        <img width={70} src={emailMarketing} alt="Learn With Experts" />
                        <p className='text-md fw-semibold mt-3 mb-1'>E-Mail Marketing</p>
                        <p className='text-slate-400 text-sm'>Curate anding area share Pluralsight
                            content to reach your</p>
                    </div>
                </section>
            </div>

        </div>
    )
}

export default WhyChoose