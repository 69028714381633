import React, { useEffect, useState } from 'react'
import { testimonial } from '../../Context/Data'
import ArrowRightUp from '../../svg/ArrowRightUp'
import TimePicker from 'react-time-picker'
import { LocalizationProvider, StaticTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

const Testimonial = () => {

    const [comment, setcomment] = useState(testimonial[0])
    const [id, setid] = useState(0)
    const [orbit1, setOrbit1] = useState('rotateanimation')
    const [orbit2, setOrbit2] = useState('rotateanimation2')

    const [animation, setanimation] = useState()
    useEffect(() => {
        setcomment(testimonial[id])
        setanimation('spinAnimation')
        setTimeout(() => {
            setanimation('')
        }, 1000);
        // alert(id)
    }, [id])

    return (
        <div className='min-h-[90vh] flex '>
            <div className='m-auto '>

                <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>Testimonial </p>

                <div className='row container my-5 mx-auto '>
                    {/* Comments */}

                    <div className='col-xl-6 mt-5 flex gap-3 items-center'>
                        {/* Arrows */}
                        <div className=''>
                            <button onClick={() => {
                                setid((prev) => {
                                    if (prev >= 0 && prev < 3) {
                                        return prev + 1
                                    }
                                    else
                                        return 0
                                })
                            }} className='-rotate-45 my-3 p-3 border-[6px] hover:bg-blue-500 hover:text-slate-50 text-slate-950 border-slate-200 rounded-full'> <ArrowRightUp size={24} /> </button>
                            <button onClick={() => {
                                setid((prev) => {
                                    if (prev >= 1 && prev < 5) {
                                        return prev - 1
                                    }
                                    else
                                        return 3
                                })
                            }} className='rotate-[135deg] my-3 p-3 border-[6px] block hover:bg-blue-500 hover:text-slate-50 text-slate-950 border-slate-200 rounded-full'> <ArrowRightUp size={24} /> </button>
                        </div>
                        {comment &&
                            <div className={`border-1 relative rounded border-sky-400 bg-sky-100 p-4 ${animation} `} >
                                <img width={60} src={comment.image} className='absolute -top-8 border-[6px] border-slate-200 shadow rounded-full ' alt="COmnt image" />
                                <img src={require('../../Assest/Quoteleft.png')} className='absolute -top-12 right-10' alt="Qoutes" />
                                <p className='mt-3 mb-1 '>{comment.name} </p>
                                {/* star */}
                                <div className='flex mb-2'>
                                    <img src={require('../../Assest/star.png')} className='mx-1' alt="Star" />
                                    <img src={require('../../Assest/star.png')} className='mx-1' alt="Star" />
                                    <img src={require('../../Assest/star.png')} className='mx-1' alt="Star" />
                                    <img src={require('../../Assest/star.png')} className='mx-1' alt="Star" />


                                </div>
                                <content>
                                    {comment.comment} </content>


                            </div>}

                    </div>

                    {/* Galaxy */}
                    <div onMouseLeave={() => { setOrbit1('rotateanimation'); setOrbit2('rotateanimation2') }}
                        //  onMouseEnter={()=>{setOrbit1('');setOrbit2('')}} 
                        className='col-xl-6 mt-5 relative'>
                        {/* Circle one */}
                        <div className={`w-[300px] h-[300px] sm:w-[400px] ${orbit1} p-0 sm:h-[400px] mx-auto relative rounded-full border-1 border-slate-500 `}>
                            <img onClick={() => setid(3)} className={`absolute cursor-pointer ${orbit1} top-16 sm:top-20 `} width={40} src={testimonial[3].image} alt="Comment image" />
                            <p className='w-[20px] absolute top-28 left-16 sm:left-32 h-[20px] rounded-full bg-pink-700 '></p>
                            <p className='w-[10px] d-none d-sm-block top-10 left-20 sm:left-0 absolute sm:top-10 sm:right-16 h-[10px] rounded-full bg-sky-400 '></p>
                            <p className='w-[10px]  absolute -bottom-10 right-32 h-[10px] rounded-full bg-slate-400 '></p>



                        </div>
                        <div className={`sm:w-[250px] w-[200px] h-[200px] ${orbit2} sm:h-[250px] top-[18%] left-[24%] sm:top-[20%] sm:left-[32%] lg:top-[19%] lg:left-[37%] xl:left-[31%] absolute xl:top-[19%] rounded-full border-1 border-slate-500 `}>
                            <img onClick={() => setid(1)} className={`absolute cursor-pointer top-7 sm:top-12 ${orbit2} `} width={40} src={testimonial[1].image} alt="Comment image" />
                            <p className='w-[10px] absolute  sm:-bottom-10 top-20 left-16 sm:left-24 h-[10px] rounded-full bg-orange-700 '></p>
                            <p className='w-[10px] absolute -top-10 right-32 h-[10px] rounded-full bg-violet-600 '></p>
                            <p className='w-[20px] absolute -bottom-10 right-32 h-[20px] rounded-full bg-slate-400 '></p>
                            <p className='w-[20px] d-none d-sm-block absolute -top-16 left-60 h-[20px] rounded-full bg-yellow-400 '></p>

                        </div>
                        {/* transform -translate-y-[50%] -translate-x-[50%] */}
                        {/* Circle three */}
                        <div className={`w-[100px] h-[100px]  sm:w-[150px] sm:h-[150px] ${orbit1} top-[34%] left-[37%] sm:top-[33%] sm:left-[38%] lg:top-[32%] lg:left-[42%] xl:left-[39%]  absolute xl:top-[32%] rounded-full border-1 border-slate-500 `}>
                            <img onClick={() => setid(2)} className={`absolute top-16 sm:top-24 cursor-pointer ${orbit1} `} width={40} src={testimonial[2].image} alt="Comment image" />
                            <p className='w-[10px]  absolute bottom-20 right-10  sm:-bottom-10 sm:right-32 h-[10px] rounded-full bg-sky-400 '></p>
                            <p className='w-[20px]  absolute -top-20 right-40 h-[20px] rounded-full bg-green-600 '></p>
                            <p className='w-[10px]  absolute -top-10 right-32 h-[10px] rounded-full bg-violet-600 '></p>
                        </div>
                        {/* transform -translate-y-[50%] -translate-x-[50%] */}


                        {/* All circle over */}

                    </div>
                </div>
                {/* <TimePicker/>
            <LocalizationProvider>
            <StaticTimePicker defaultValue={dayjs('2022-04-17T15:30')} />

            </LocalizationProvider> */}

            </div>
        </div>
    )
}

export default Testimonial