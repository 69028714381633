import React from 'react'
import ArrowRightUp from '../../svg/ArrowRightUp'
import ArrowDown from '../../svg/ArrowDown'

const WhyChooseUs = () => {
    let array=[1,2,3,4,5,6,7,8]
    return (
        <>
            <div className='py-5 '>
                <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>Why </p>
                <p className='epilogue text-center text-4xl fw-bold'>Choose us</p>
                <section className='container flex gap-3 gap-sm-1  flex-wrap justify-center sm:justify-between my-5 mx-auto'>
                    <div className='text-center w-[20rem] '>
                        {/* logo */}
                        <div className='yellowgrd rounded-3xl mx-auto flex w-[80px] h-[80px] '>
                            <img className='w-[40px] m-auto ' src={require('../../Assest/PenTip.png')} alt="Logo1" />

                        </div>
                        <p className='inter fw-semibold mt-3'>Development</p>
                        <p className='text-slate-500 text-sm inter '>Create a platform with the best and coolest quality from us.</p>
                    </div>
                    <div className='text-center w-[20rem] '>
                        {/* logo */}
                        <div className='greengrd rounded-3xl mx-auto flex w-[80px] h-[80px] p-4 '>
                            <img className='w-[50px] m-auto' src={require('../../Assest/UIUX.png')} alt="Logo1" />

                        </div>
                        <p className='inter fw-semibold mt-3'>UI/UX Designer</p>
                        <p className='text-slate-500 text-sm inter '>We provide UI/UX Design services, and of course with the best quality</p>
                    </div>
                    <div className='text-center w-[20rem] '>
                        {/* logo */}
                        <div className='bluegrd rounded-3xl mx-auto flex w-[80px] h-[80px] p-4 '>
                            <img className='w-[50px] m-auto' src={require('../../Assest/pen.png')} alt="Logo1" />

                        </div>
                        <p className='inter fw-semibold mt-3'>Development</p>
                        <p className='text-slate-500 text-sm inter '>Create a platform with the best and coolest quality from us.</p>
                    </div>
                    <div className='text-center w-[20rem] '>
                        {/* logo */}
                        <div className='redgrd rounded-3xl mx-auto flex w-[80px] h-[80px] p-4 '>
                            <img className='w-[50px] m-auto' src={require('../../Assest/Trophy.png')} alt="Logo1" />

                        </div>
                        <p className='inter fw-semibold mt-3'>Best Award history</p>
                        <p className='text-slate-400 text-sm inter '>we have placed various awards for our performance</p>
                    </div>
                </section>


            </div>
            {/* Most popular category */}
            <section className='my-3 container mx-auto col-lg-10 '>
                <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>Most Popular </p>
                <p className='epilogue text-center text-4xl fw-bold'>Category</p>
                <div className='container mx-autp flex flex-wrap my-5 justify-between gap-2'>

                    {array.map((x)=>(
                        <div className='p-2 border-2 bg-sky-100 w-[11rem] sm:w-[16rem] justify-between items-center flex rounded border-sky-200 '>
                        <div className='flex gap-2 items-center'>
                            <img src={require('../../Assest/pen-tool.png')} alt="Penlogo" width={30} />
                            <p className='m-0 inter text-blue-600'>Design </p>
                        </div>
                        <div className='rounded h-fit fw-semibold p-2 bg-blue-600 text-white'>
                            <ArrowRightUp size={20}/>
                        </div>
                    </div>
                    ))}

                </div>
                <button className='grd2 flex items-center gap-3 inter text-white p-2 px-3 rounded mx-auto'>View All 
                <p className='-rotate-90 m-0 '><ArrowDown/></p> </button>

            </section>
        </>
    )
}

export default WhyChooseUs