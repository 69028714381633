import React from 'react'
import Slider from 'react-slick'
import SliderRight from '../../svg/SliderRight';
import SliderLeft from '../../svg/SliderLeft';

const OurWorks = () => {
  var settings = {
    // dots: false,

    speed: 500,
    slidesToShow: 4,
    // rtl: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    arrows:false,

    // nextArrow: <SliderRight size={26} />,
    // prevArrow: <SliderLeft size={26} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  var settings2 = {
    // dots: false,
    arrows:false,
    speed: 500,
    slidesToShow: 4,
    rtl: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,

    // nextArrow: <SliderRight size={26} />,
    // prevArrow: <SliderLeft size={26} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  let section1 = ['../../Assest/event1.png', '../../Assest/event2.png', '../../Assest/event3.png', '../../Assest/event4.png', '../../Assest/event5.png']
  return (
    <div className='min-h-[70vh] lg:min-h-[100vh] flex items-center justify-center  '>
      <div className={` my-auto text-center container mx-auto`}>

        <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>Our </p>
        <p className='epilogue text-center text-4xl fw-bold'>Events </p>
        <p className='w-8/12 mx-auto'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime eius nam, impedit ullam libero amet numquam consequatur sint cupiditate ex.</p>
        <div className='container mx-auto '>
        <Slider {...settings} className='my-4' >
          {
            section1 && section1.map((x, index) => {
              return (
                <img src={x} alt="Event Images" key={index} />
              )
            })
          }
        </Slider>
        </div>
        <div className='container mx-auto '>

        <Slider {...settings2} className='items-center my-3'>
          {
            section1 && [...section1].reverse().map((x, index) => {
              return (
                <img src={x} className='my-auto' alt="Event Images" key={index} />
              )
            })
          }
        </Slider>
      </div>
      </div>


    </div>
  )
}

export default OurWorks