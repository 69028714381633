import React from 'react'
import Slider from 'react-slick'

const SubjectSlides = () => {
    let settings={
        dots: false,
        arrows: false,
        speed: 2000,
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        responsive: [
            {
                breakpoint: 1040,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 830,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    let blog=[
        {
            img:'../../Assest/blog2.jpg',
            title:'Opening Day of Boating Season, Seattle WA',
            des:'Of course the Puget Sound is very watery, and where there is water, there are boats. Today is'
        },
        {
            img:'../../Assest/blog3.png',
            title:'Opening Day of Boating Season, Seattle WA',
            des:'Of course the Puget Sound is very watery, and where there is water, there are boats. Today is'
        },
        {
            img:'../../Assest/blog5.png',
            title:'Opening Day of Boating Season, Seattle WA',
            des:'Of course the Puget Sound is very watery, and where there is water, there are boats. Today is'
        }

    ]
  return (
    <div className='my-4 '>
        <p className='fw-medium text-2xl flex items-center gap-2 '> <span className='p-[3px] bg-blue-500 rounded-full h-[12px] w-[1px] '></span> Digital Marketing </p>
        <Slider {...settings} className='py-2'>
            {
                blog.map((x,index)=>{
                    return(
                        <div className='p-3 bg-white rounded shadow-sm mt-3 my-2'>
                            <img src={x.img} className='rounded-xl h-[20rem] sm:h-[10rem] lg:h-[15rem] object-cover ' alt="Image" />
                            <p className='text-lg fw-medium mt-3' >{x.title} </p>
                            <p className='text-slate-500 text-sm '>{x.des} </p>
                        </div>
                    )
                })
            }



        </Slider>
    </div>
  )
}

export default SubjectSlides