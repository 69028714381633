import React, { useContext } from 'react'
import { Store } from '../Context/StoreProvider'
import NavBarCom from '../Component/HomeComponents/NavBarCom'
import HomeBanner from '../Component/HomeComponents/HomeBanner'
import PopularCourses from '../Component/HomeComponents/PopularCourses'
import WhyChooseUs from '../Component/HomeComponents/WhyChooseUs'
import HowItWorks from '../Component/HomeComponents/HowItWorks'
import Testimonial from '../Component/HomeComponents/Testimonial'
import CompaniesTrustUs from '../Component/HomeComponents/CompaniesTrustUs'
import OurWorks from '../Component/HomeComponents/OurWorks'
import Contactcom from '../Component/HomeComponents/Contactcom'
import Footer from '../Component/Footer'
import OurBlog from '../Component/HomeComponents/OurBlog'
import HowItWorksMobile from '../Component/HomeComponents/HowItWorksMobile'


const LandingPage = () => {
  return (
    <div className='bg1' >
        <HomeBanner/>
        <PopularCourses/>
        <WhyChooseUs/>
        <HowItWorks/>
        <HowItWorksMobile/>
        <Testimonial/>
        <CompaniesTrustUs/>
        <OurWorks/>
        <OurBlog/>
        <Contactcom/>
    </div>
  )
}

export default LandingPage