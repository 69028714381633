import React, { useEffect, useState } from 'react'
import ParticularCard from './ParticularCard'
import { courses } from '../../Context/Data'

const PopularCourses = () => {
    const [selectedobj, setselectedobj] = useState(0)
    const heading = []
    const heading2 = [...courses].map((obj) => {
        if (heading.indexOf(obj.category) == -1) {
            heading.push(obj.category)
        }
    })
    const [arry, setArry] = useState([...courses].filter((obj) => obj.category == heading[0]))
    useEffect(() => {
        setArry([...courses].filter((obj) => obj.category == heading[selectedobj]))
    }, [selectedobj])

    return (
        <div className='min-h-[100vh] py-4 '>
            <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>Popular </p>
            <p className='epilogue text-center text-4xl fw-bold '>Courses</p>
            {/* Button section */}
            <div className='bg2 rounded-full p-1 flex gap-lg-3 inter border-1 bclr1 w-fit mx-auto'>

                {
                    heading.map((x, index) => {
                        return (
                            <button key={index} onClick={() => setselectedobj(index)}
                                className={` transition duration-500 rounded-full  px-lg-4  p-2 ${selectedobj == index ? 'text-white bg-blue-600 ' : ''} `}>
                                {x}
                            </button>
                        )
                    })
                }
            </div>
            {/* Cards */}
            <div className='container col-sm-10 mx-auto flex gap-4 justify-center sm:justify-between  flex-wrap my-5'>
                {
                    arry.map((obj) =>
                        <ParticularCard obj={obj} />)

                }

            </div>
            <button className='grd2 flex inter text-white p-2 px-3 rounded mx-auto'>Explore all Courses</button>
        </div>
    )
}

export default PopularCourses