import React from 'react'

const HowItWorksMobile = () => {
    return (
        <div className='my-20  d-sm-none '>
            <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>How </p>
            <p className='epilogue text-center text-4xl fw-bold'>It's works</p>

            <div className='row container mx-auto  '>
                {/* section starts dottedBorder*/}
                <div className='p-0   w-full min-h-full'>
                    <h6 className='h-[30px] '></h6>
                    <div className='  w-full '>
                        <img src={require('../../Assest/join.png')} className='mx-auto my-16' alt="Join image" width={150} />
                    </div>
                    {/* Connector */}
                    <div className='h-[100px] w-0 mx-auto relative dottedBorder '>
                        <p className='circle top-0  transform -translate-y-3 '></p>
                    </div>

                    <div className=' w-full '>
                        <div className='p-3 rounded bclr border-2 w-4/6 bg-sky-100 border-sky-200 mx-auto '>
                            <p className='text-xl fw-semibold'><span>#1</span> Code Reviews</p>
                            <p className=''>Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells</p>
                        </div>
                    </div>
                    {/* Connector */}
                    <div className='h-[100px] w-0 mx-auto relative dottedBorder '>
                    </div>

                    <div className='relative w-full '>
                        <img src={require('../../Assest/comptr.png')} className='mx-auto mb-16' alt="Join image" width={150} />

                    </div>
                    <h6 className='h-[30px] '></h6>
                </div>
                <div className='relative'>
                    <div className='h-[100px] w-0 mx-auto relative dottedBorder '>
                        <p className='circle top-0  transform -translate-y-3 '></p>
                    </div>

                    <div className='p-3 rounded bclr border-2  w-4/6 bg-sky-100 border-sky-200 mx-auto '>
                        <p className='text-xl fw-semibold'><span>#2</span> Code Reviews</p>
                        <p className=''>Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells</p>
                    </div>
                    <div className='h-[100px] w-0 mx-auto relative dottedBorder '>
                        {/* <p className='circle top-0  transform -translate-y-3 '></p> */}
                    </div>

                    <img src={require('../../Assest/Trophy_perspective_matte.png')} alt="Computer logo" width={170} className='mb-16 mx-auto' />
                    <div className='h-[100px] w-0 mx-auto relative dottedBorder '>
                        <p className='circle top-0  transform -translate-y-3 '></p>
                    </div>
                    <div className='p-3 rounded bclr border-2 w-4/6 bg-sky-100 border-sky-200 mx-auto '>
                        <p className='text-xl fw-semibold'><span>#3</span> Code Reviews</p>
                        <p className=''>Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells</p>
                    </div>
                    <div className='h-[100px] w-0 mx-auto relative dottedBorder '>
                        {/* <p className='circle top-0  transform -translate-y-3 '></p> */}
                    </div>
                    <img width={180} src={require('../../Assest/certificate.png')} alt="Certificate" className='mx-auto my-3' />

                </div>
            </div>

        </div>
    )
}

export default HowItWorksMobile