import React from 'react'

const OurPlans = () => {
    let PlanDetails = [
        {
            'Level': 'Basic',
            'BestValue': false,
            'points': ['Ultimate Bandwitch', 'Encrypted Connection', 'No traffic Logs', 'Works on All Devices']
        },
        {
            'Level': 'Advanced',
            'BestValue': true,
            'points': ['Ultimate Bandwitch', 'Encrypted Connection', 'No traffic Logs', 'Works on All Devices']
        },
        {
            'Level': 'Premium',
            'BestValue': false,
            'points': ['Ultimate Bandwitch', 'Encrypted Connection', 'No traffic Logs', 'Works on All Devices']
        }
    ]
    return (
        <div className='my-5 xl:min-h-[90vh] flex'>
        <div className=' my-4 container col-xl-10 m-auto '>
            <p className='epilogue uppercase letter-spacing2 text-center text-sky-600'>Our </p>
            <p className='epilogue m-0 text-center text-4xl fw-bold'>Plans </p>
            <p className='epilogue text-lg text-center'>Discover , collect and sell Discover, collect and sell </p>


            <div className='flex flex-wrap gap-2 justify-around lg:justify-between '>
                {
                    PlanDetails.map((x, index) => {
                        return (
                            <div style={{ backgroundColor: x.BestValue ? 'rgb(69,125,247)' : 'white' }} 
                            className={` w-[21rem] rounded-xl lg:w-[18rem] xl:w-[23rem] h-[30rem] ${x.BestValue?'text-white':'text-black border-1 border-slate-400'}
                             relative shadow-md  bg-opacity-80 `}>
                                {x.BestValue && <div className='relative w-fit left-10 rounded-b-xl bg-amber-500 bg-opacity-90 text-white p-2 '>
                                    Best Value
                                </div>}
                                {
                                    !x.BestValue&&<p className='h-[30px]'></p>
                                }
                                <p className={`  inter text-2xl my-2 fw-light text-center `}>Advanced</p>
                                <img src={require('../../Assest/Premium.png')} className='mx-auto' width={60} alt="Assest" />
                                <div className='p-4'>
                                    {
                                        x.points.map((y) => (
                                            <p className='flex w-5/6 lg:w-full xl:w-5/6 mx-auto items-center gap-2'>
                                                <img src={require('../../Assest/tickMark.png')} width={30} alt="Tick Mark" />
                                                {y} </p>
                                        ))
                                    }


                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div></div>
    )
}

export default OurPlans