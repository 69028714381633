import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

const ContactModel = (props) => {
    const { show, setShow, courseName } = props
    let [contactinfo, setContactInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        terms: false
    })
    const handleContact = (e) => {
        let { value, name } = e.target
        if (name == 'phone' && value.length > 10) {
            value = contactinfo.phone
        }
        setContactInfo((prev) => ({
            ...prev,
            [name]: value
        }))

    }
    const handleContactform=()=>{
        if(contactinfo.firstName!=''&&contactinfo.email!='' &&contactinfo.phone!=''&&contactinfo.terms ){
            document.getElementById('errormsg').innerHTML=``


        }
        else{
            document.getElementById('errormsg').innerHTML=`*Complete the above form and click the 'I agree' check mark  `
        }
    }
    return (
        <div>
            <Modal centered className='bg-blue-600 bg-opacity-5 inter   ' size='' show={show} onHide={() => setShow(false)} >
                <div className='p-1 rounded-2xl text-center '>
                    <div className='grd3 p-3 rounded-t-2xl    '>
                        <img src={require('../../Assest/DarkMerida.png')} className='mx-auto' alt="Merida Logo" />
                        <h4 className='mx-auto my-4 w-fit fw-semibold text-blue-600 '>{courseName} </h4>
                        <h6>Why You Should Learn With Merida ? </h6>
                        <section className='flex my-2 flex-wrap text-sm'>
                            <div className='flex my-2 text-slate-600 text-xs justify-center items-center w-1/2'>
                                <img src={require('../../Assest/check-square.png')} width={20} alt="Check Square" />
                                100% Assured Internship
                            </div>
                            <div className='flex my-2 text-slate-600 text-xs justify-center items-center w-1/2'>
                                <img src={require('../../Assest/check-square.png')} width={20} alt="Check Square" />
                                100% Assured Internship
                            </div>
                            <div className='flex my-2 text-slate-600 text-xs justify-center items-center w-1/2'>
                                <img src={require('../../Assest/check-square.png')} width={20} alt="Check Square" />
                                100% Assured Internship
                            </div>
                            <div className='flex my-2 text-slate-600 text-xs justify-center items-center w-1/2'>
                                <img src={require('../../Assest/check-square.png')} width={20} alt="Check Square" />
                                100% Assured Internship
                            </div>
                            <div className='flex my-2 text-slate-600 text-xs justify-center items-center w-1/2'>
                                <img src={require('../../Assest/check-square.png')} width={20} alt="Check Square" />
                                100% Assured Internship
                            </div>
                            <div className='flex my-2 text-slate-600 text-xs justify-center items-center w-1/2'>
                                <img src={require('../../Assest/check-square.png')} width={20} alt="Check Square" />
                                100% Assured Internship
                            </div>
                        </section>
                    </div>
                    {/* INput */}
                    <div className='p-3 text-sm'>
                        <div className='flex justify-between'>
                            <input style={{ backgroundColor: 'rgb(236,244,250)' }} type="text" className='border-1 w-40 sm:w-fit outline-none border-slate-300 rounded  p-2 ' 
                            value={contactinfo.firstName} name='firstName' onChange={handleContact} placeholder='First Name*' />
                            <input style={{ backgroundColor: 'rgb(236,244,250)' }}   value={contactinfo.lastName} name='lastName' onChange={handleContact} 
                            type="text" className='border-1 w-40 sm:w-fit outline-none border-slate-300 rounded  p-2 ' placeholder='Last Name' />
                        </div>
                        <input style={{ backgroundColor: 'rgb(236,244,250)' }} type="email"  value={contactinfo.email} name='email' onChange={handleContact} 
                         className='border-1 outline-none border-slate-300 w-full my-4 rounded  p-2 ' placeholder='Email*' />
                        <input style={{ backgroundColor: 'rgb(236,244,250)' }} type="number" value={contactinfo.phone} name='phone' onChange={handleContact}
                         className='border-1 outline-none border-slate-300 w-full rounded  p-2 ' placeholder='Phone Number*' />
                        <p className='text-red-600 text-center h-[20px] text-xs my-2 ' id="errormsg"></p>
                        {/* Button download */}
                        <button onClick={handleContactform} className='w-full text-white rounded p-[10px] bg-blue-700 '> Download now </button>

                        <div className='my-2 flex items-center justify-center gap-2'>
                            <input type="checkbox" checked={contactinfo.terms} onChange={() => {
                                setContactInfo((prev) => ({
                                    ...prev,
                                    terms: contactinfo.terms ? false : true
                                }))
                            }}
                                id='check' />
                            <label htmlFor="check" className='text-xs'>I agree with Merida <button className='text-blue-600'>Privacy Policy </button> &
                                <button className='text-blue-600'> Terms and conditions </button>
                            </label>
                        </div>
                        <p className='text-center text-slate-600 text-xs'> We do not spam or sell your data to anyone </p>

                    </div>
                </div>
            </Modal>


        </div>
    )
}

export default ContactModel