import React, { useEffect, useState } from 'react'
import NavBarCom from '../Component/HomeComponents/NavBarCom'
import CourseBanner from '../Component/Particularcourse/CourseBanner'
import CourseDescription from '../Component/Particularcourse/CourseDescription'
import Coursecurriculum from '../Component/Particularcourse/Coursecurriculum'
import { book, certificateIcon, clockImg, facebookShare, instagramShare, levelIcon, puzzle, scollerCup, twitterShare, whatsappShare, youtubeShare } from '../Utils/Image'
import BookIcon from '../svg/BookIcon'
import CalenderIcon from '../svg/CalenderIcon'
import OurPlans from '../Component/Particularcourse/OurPlans'
import WhoCanLearn from '../Component/Particularcourse/WhoCanLearn'
import WhatWeAreProviding from '../Component/Particularcourse/WhatWeAreProviding'
import WhyChoose from '../Component/Particularcourse/WhyChoose'
import OurKnowledgePartners from '../Component/Particularcourse/OurKnowledgePartners'
import HowYouJorney from '../Component/Particularcourse/HowYouJorney'
import OurOtherCourses from '../Component/Particularcourse/OurOtherCourses'
import FAQ from '../Component/Particularcourse/FAQ'
import { Link, useParams } from 'react-router-dom'
import { courses } from '../Context/Data'
import ContactModel from '../Component/HomeComponents/ContactModel'

const ParticularCourse = () => {
    let { slug } = useParams()
    let [course, setCourse] = useState()
    let [show, setshow] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0);
        if (slug) {
            setCourse(courses.find((x) => x.slug == slug))
            console.log(courses.find((x) => x.slug == slug));

        }
    }, [slug])
    let [activesection, setActivesection] = useState('about')
    return (
        <div className='bg-sky-50 bg-opacity-50 '>
            <CourseBanner />
            {/* Course Details */}
            <div className='container col-lg-10 py-3 flex mx-auto min-h-[10vh] '>
                <section className='my-auto flex gap-3 flex-wrap'>
                    <button onClick={() => setActivesection('about')} className={`rounded-full transi text-sm p-2 px-3 ${activesection == 'about' ? 'bg-blue-500 text-white' : 'bg-slate-500 text-black bg-opacity-30'}  `}>
                        About course
                    </button>
                    <button onClick={() => setActivesection('curriculum')} className={`rounded-full transi text-sm p-2 px-3 ${activesection == 'curriculum' ? 'bg-blue-500 text-white' : 'bg-slate-500 text-black bg-opacity-30'}  `}>
                        Curriculum
                    </button>
                    <button onClick={() => setActivesection('review')} className={`rounded-full transi text-sm p-2 px-3 ${activesection == 'review' ? 'bg-blue-500 text-white' : 'bg-slate-500 text-black bg-opacity-30'}  `}>
                        Reviews
                    </button>
                    <a onClick={() => {
                        setActivesection('about');
                        const faqSection = document.getElementById('faq'); // Get the FAQ section element by its ID
                        faqSection.scrollIntoView({ behavior: 'smooth', block: 'end' });

                    }} className={`rounded-full cursor-pointer text-decoration-none transi text-sm p-2 px-3 ${activesection == 'faq' ? 'bg-blue-500 text-white' : 'bg-slate-500 text-black bg-opacity-30'}  `}>
                        FAQ
                    </a>
                </section>
            </div>
            {/* Course Details */}
            <div className='row container col-xl-10 mx-auto min-h-[30vh] justify-between'>
                <section className='col-lg-8    my-2'>
                    <div className='w-full '>
                        {activesection == 'about' && course && <CourseDescription data={course} />}
                        {activesection == 'curriculum' && <Coursecurriculum />}
                    </div>
                </section>
                <section className='col-lg-4 my-2 '>
                    {/* Card 1 */}
                    <div className='rounded-xl shadow border-1 bg-white p-[20px] xl:p-[40px] min-h-[20vh] border-slate-500 border-opacity-35 '>
                        <div className='bg-blue-500 rounded-xl shadow mb-3 p-4 text-white '>
                            <h4 className=' text-2xl'>Industry Oriented Curriculum </h4>
                            <p className='text-xs'>
                                {course && course.preReq && course.preReq.map((y) => y + ', ')}
                            </p>
                        </div>
                        <h4> Course includes : </h4>
                        <div className='border-b-2 px-1 mt-3 py-[6px] items-center flex justify-between '>
                            <div className='flex gap-2 items-center'>  <img className='w-fit h-fit' src={levelIcon} alt="Level icon" /> Level </div>
                            <p className='text-slate-500 text-opacity-80  m-0'>Expert </p>
                        </div>
                        <div className='border-b-2 px-1 mt-3  py-[6px] flex justify-between '>
                            <div className='flex gap-2 items-center'> <img src={clockImg} className='w-fit h-fit' alt="Image2" /> Duration </div>
                            <p className='text-slate-500 text-opacity-80  m-0'> 11h 20m </p>
                        </div>
                        <div className='border-b-2 px-1 mt-3 py-[6px] flex justify-between '>
                            <div className='flex gap-2 items-center'> <img src={book} className='w-fit h-fit' alt="Image3" /> Lessons </div>
                            <p className='text-slate-500 text-opacity-80 m-0 '>12 </p>
                        </div>
                        <div className='border-b-2 px-1 mt-3 py-[6px] flex justify-between '>
                            <div className='flex gap-2 items-center'>  <img src={puzzle} className='w-fit h-fit' alt="Image3" /> Quizzes </div>
                            <p className='text-slate-500 text-opacity-80 m-0 '> 142 </p>
                        </div>
                        <div className='border-b-2 px-1 mt-3 py-[6px] flex justify-between '>
                            <div className='flex gap-2 items-center'>  <img src={certificateIcon} className='w-fit h-fit' alt="Image3" /> Certifications </div>
                            <p className='text-slate-500 text-opacity-80 m-0 '> Yes </p>
                        </div>
                        <div className='border-b-2 px-1 mt-3 py-[6px] flex justify-between '>
                            <div className='flex gap-2 items-center'>  <img src={scollerCup} className='w-fit h-fit' alt="Image3" /> Earn Upto </div>
                            <p className='text-slate-500 text-opacity-80 m-0 '> 25K </p>
                        </div>
                        <h4 className='my-2 '>Share this course : </h4>
                        {/* Icons */}
                        <div className='flex gap-2'>
                            <button className='bg-slate-500 bg-opacity-15 w-[40px] flex h-[40px] p-2 rounded-full'>
                                <img src={facebookShare} className='m-auto  ' alt="facebook" />
                            </button>
                            <button className='bg-slate-500 bg-opacity-15 w-[40px] flex h-[40px] p-2 rounded-full'>
                                <img src={twitterShare} className='m-auto  ' alt="facebook" />
                            </button>
                            <button className='bg-slate-500 bg-opacity-15 w-[40px] flex h-[40px] p-2 rounded-full'>
                                <img src={whatsappShare} className='m-auto  ' alt="facebook" />
                            </button>
                            <button className='bg-slate-500 bg-opacity-15 w-[40px] flex h-[40px] p-2 rounded-full'>
                                <img src={instagramShare} className='m-auto  ' alt="facebook" />
                            </button>
                            <button className='bg-slate-500 bg-opacity-15 w-[40px] flex h-[40px] p-2 rounded-full'>
                                <img src={youtubeShare} className='m-auto  ' alt="facebook" />
                            </button>
                        </div>
                        <hr />
                        <button onClick={()=>setshow(true)} className='uppercase flex gap-2 justify-center items-center grd bclr1 p-2 px-3 text-lg fw-semibold w-full rounded text-white border-2 '>
                            Download Brochure <img width={30} className='h-fit' src={require('../Assest/browcer.png')} alt="Browcer image" />
                        </button>

                    </div>
                    {/* Related Post */}
                    <div className='rounded-xl my-2 shadow border-1 bg-white p-[22px] xl:p-[40px] min-h-[20vh] border-slate-500 border-opacity-35 '>
                        <h4 className=' text-2xl'>Related Post </h4>
                        <div className='flex gap-2 my-2'>
                            <img className='object-cover w-[80px] rounded h-[70px] ' src={require('../Assest/Blog1.png')} alt="Blog Image" />
                            <div>
                                <p className='m-0 flex items-center text-slate-400 gap-2'>
                                    <span className='text-violet-600 ' > <CalenderIcon /> </span>
                                    April 13, 2024
                                </p>
                                <p className='m-0 fw-semibold text-sm pe-4'>The Right Learning Path for you</p>
                            </div>
                        </div>
                        <div className='flex gap-2 my-2'>
                            <img className='object-cover w-[80px] rounded h-[70px] ' src={require('../Assest/Blog1.png')} alt="Blog Image" />
                            <div>
                                <p className='m-0 flex items-center text-slate-400 gap-2'>
                                    <span className='text-violet-600 ' > <CalenderIcon /> </span>
                                    April 13, 2024
                                </p>
                                <p className='m-0 fw-semibold text-sm pe-4'>The Right Learning Path for you</p>
                            </div>
                        </div>
                        <div className='flex gap-2 my-2'>
                            <img className='object-cover w-[80px] rounded h-[70px] ' src={require('../Assest/Blog1.png')} alt="Blog Image" />
                            <div>
                                <p className='m-0 flex items-center text-slate-400 gap-2'>
                                    <span className='text-violet-600 ' > <CalenderIcon /> </span>
                                    April 13, 2024
                                </p>
                                <p className='m-0 fw-semibold text-sm pe-4'>The Right Learning Path for you</p>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
            {course && <ContactModel show={show} setShow={setshow} courseName={course.title} />}
            <OurPlans />
            <WhoCanLearn />
            <p className='h-[30px] '></p>
            <WhatWeAreProviding />
            <WhyChoose />
            <OurKnowledgePartners />
            <HowYouJorney />
            <OurOtherCourses />
            {course && <FAQ data={course.faq} />}


        </div>
    )
}

export default ParticularCourse