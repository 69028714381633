import React, { useEffect, useState } from 'react'
import { courses } from '../Context/Data';

const FilterSource = (props) => {
    let { obj, setItemOffset, setOffcanvas, setdata } = props;
    let newArray = [];

    let [filterArray, setFilterArray] = useState([])
    obj.forEach(x => {
        // Check if category already exists in newArray
        let existingCategoryIndex = newArray.findIndex(item => Object.keys(item)[0] === x.category);

        if (existingCategoryIndex !== -1) {
            // If category exists, push object into existing array
            newArray[existingCategoryIndex][x.category].push(x);
        } else {
            // If category doesn't exist, create a new array for it
            let newObj = {};
            newObj[x.category] = [x]; // Create an array containing object 'x'
            newArray.push(newObj);
        }
    });
    console.log(newArray);
    const handleChange = () => {
        let arry = []
        filterArray.forEach((x) => {
            const arry2 = courses.filter((y) => y.category == x)
            arry.push(...arry2)
        })
        setdata(arry)
        console.log(arry);
        setItemOffset(0)
        // setOffcanvas(false)
    }
    useEffect(() => {
        if (filterArray.length > 0) {
            handleChange()
        }
    }, [filterArray])


    return (
        <div className='w-full ms-sm-4  '>
            <div className='rounded p-3 sm:bg-slate-400 sm:bg-opacity-10 '>
                <p className=' fw-semibold' >Categories</p>
                {
                    newArray && newArray.map((x, index) => {
                        let keys = Object.keys(x)
                        console.log(filterArray);
                        let entries = Object.values(x)
                        return (
                            <div onClick={() => {
                                if (filterArray.indexOf(keys[0]) == -1) {
                                    setFilterArray((prev) => [
                                        ...prev,
                                        keys[0]
                                    ])
                                }
                                else {
                                    setFilterArray((prev) => prev.filter((x) => x != keys[0]))
                                }
                            }}  >
                                <input checked={filterArray.find((y) => y == keys[0])} type="checkbox" id={`${index}`} className='w-[28px] cursor-pointer ' />
                                <label htmlFor={`${index}`}>{keys[0]} ({entries[0].length}) </label>
                            </div>
                        )
                    })
                }

            </div>
            <div className='rounded p-3 my-2 sm:bg-slate-400 sm:bg-opacity-10 '>
                <h4>Skill level</h4>

                <div>
                    <input type="checkbox" className='w-[28px] cursor-pointer ' />
                    <label >All Skills </label>
                </div>
                <div>
                    <input type="checkbox" className='w-[28px] cursor-pointer ' />
                    <label > Beginner (55) </label>
                </div>
                <div>
                    <input type="checkbox" className='w-[28px] cursor-pointer ' />
                    <label > Intermediate (22) </label>
                </div>
                <div>
                    <input type="checkbox" className='w-[28px] cursor-pointer ' />
                    <label > High (42) </label>
                </div>
            </div>

        </div>
    )
}

export default FilterSource