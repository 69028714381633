import React from 'react'

const WhySlaContent = () => {
    return (
        <div className='container mx-auto'>

            <p className='epilogue uppercase letter-spacing2 text-center text-blue-600'>WHY prefer</p>
            <p className='epilogue m-0 text-center text-4xl fw-bold'>Skill learning academy <br /> over <span className='text-blue-600'>others? </span> </p>

            <div className='rounded shadow overflow-y-scroll scrollbar2  h-[90vh] my-5 bg-white p-2 p-sm-5 '>
                {
                    [1, 2, 3, 4, 5, 6, 7, 8, 1, 1, 1, 1].map((x) => (
                        <div>
                            <p>Structured Learning  </p>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non accusantium porro neque modi ea culpa, fugit nemo quos ullam ab possimus fuga harum et cupiditate nam delectus voluptatem iste magnam corrupti
                                alias? Eveniet quas inventore aperiam nulla a ex soluta! </p>

                        </div>
                    ))

                }

            </div>

        </div>
    )
}

export default WhySlaContent