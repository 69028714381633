export const darklogo = '../Assest/DarkMerida.png'
export const ecplise1='../Assest/Ellipse 2.png'
export const ecplise2='../Assest/Ellipse 3.png'
export const homebannerpic='../Assest/BannerPic.png'
export const homeContact='../Assest/HomeContact.png'
export const facebookIcon='../Assest/facebook.png'
export const catIcon='../Assest/cat.png'
export const githubIcon='../Assest/github.png'
export const bubbltIcon='../Assest/bubble.png'
export const instagram='../Assest/instagram.png'
export const sendIcon='../Assest/send.png'
export const clockImg='../Assest/clock.png'
export const certificateIcon='../Assest/certificateIcon.png'
export const puzzle='../Assest/puzzle.png'
export const scollerCup='../Assest/scollerCap.png'
export const book='../Assest/book.png'
export const levelIcon='../Assest/Level.png'
export const instagramShare='../Assest/instagramS.png'
export const whatsappShare='../Assest/whatsapp.png'
export const twitterShare='../Assest/tiwitterS.png'
export const youtubeShare='../Assest/youtubeS.png'
export const facebookShare='../Assest/facebookS.png'
export const learnWithExperts='../Assest/learnwithexperts.png'
export const learnAnything='../Assest/learnAnything.png'
export const onlineCertificate='../Assest/onlineCertificate.png'
export const emailMarketing='../Assest/EmailMarketing.png'

export const amazonLogo='../Assest/Amazon.png'
export const airbnbLogo ='../Assest/Airbnb.png'
export const googleLogo ='../Assest/Google.png'
export const bookMyShowLogo='../Assest/BookMyShowLogo.png'
export const microsoftLogo='../Assest/Microsoft.png'
export const fedExLogo='../Assest/FedEx.png'
export const walmartLogo='../Assest/Walmart.png'
export const oyoLogo='../Assest/Oyo.png'
export const olaLogo='../Assest/OLALogo.png'
export const instructor='../Assest/instructor.png'
export const student='../Assest/student.png'
