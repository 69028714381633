import React from 'react'
import CalenderIcon from '../../svg/CalenderIcon'
import CommentIcon from '../../svg/CommentIcon'
import ProfileFill from '../../svg/ProfileFill'
import { ClockIcon } from '@mui/x-date-pickers'
import ArrowDown from '../../svg/ArrowDown'
import { facebookShare, instagramShare, twitterShare, whatsappShare } from '../../Utils/Image'

const BlogDetails = () => {
    return (
        <div>
            <img className='rounded w-full h-[50vh] lg:h-[30vh] xl:h-[55vh] object-cover  ' src={require('../../Assest/blog3.png')} alt="Blog" />
            <p className='flex gap-2 items-center mt-2 text-xs text-slate-500 '>
                <span className='text-violet-600'><CalenderIcon /> </span> 20 July, 2024
                <span className='text-violet-600' ><ProfileFill /> </span> by Admin
                <span className='text-violet-600' ><ClockIcon /> </span> 5 min Read
                <span className='text-violet-600'> <CommentIcon /></span> 05 Comments
            </p>
            {/* Heading 1 */}
            <h1 className='poppins fw-semibold text-3xl'>How To Become idiculously Self-Aware In 20 Minutes </h1>
            {/* Para1 */}
            <p className='text-slate-500 '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi autem ex blanditiis animi laborum accusantium maiores provident incidunt sunt at, placeat quos repellat perspiciatis magnam deserunt aliquam ipsum neque excepturi qui corrupti tempora dolorem tenetur a ratione! Quam atque et accusantium natus tempore quos labore perspiciatis quo ea laboriosam
                repudiandae minus reiciendis error, accusamus neque fugiat similique libero ad cumque. </p>
            {/* Highlight */}
            <div className='p-3 w-10/12 my-3 mx-auto flex gap-3 rounded-e-2xl border-s-4 bg-blue-600 bg-opacity-15 bclr2 border-opacity-100 '>
                <p className='inter text-sm '>
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, quis autem. Officia unde nulla numquam deleniti illum voluptate dolore
                    ratione ullam reprehenderit consequatur, iure alias iste natus tempora odit! Saepe."
                </p>
                <img src={require('../../Assest/blueQoutes.png')} width={50} className='h-fit ' alt="Qoutes" />
            </div>
            {/* Second para 2 */}
            <p className='text-slate-500 '>
                Maximus ligula eleifend id nisl quis interdum. Sed malesuada tortor non turpis semper bibendum. Ut ac nisi porta, malesuada risus nonrra
                dolo areay Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.
            </p>
            {/* Heading 2 */}
            <h5 className='poppins text-2xl '>What Will I Learn From This Course? </h5>
            {/* Para 3 */}
            <p className=' text-slate-500  ' >
                Maximus ligula eleifend id nisl quis interdum. Sed malesuada tortor non turpis semper bibendum. Ut ac nisi porta, malesuada risus non
                viverra dolor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
            </p>
            {/* Points */}
            <div>
                <p className='flex m-0 items-center fw-semibold my-3 gap-2'>
                    <span className=' p-2 makeshadow bg-blue-500 rounded-full text-white  -rotate-90'><ArrowDown size={12} />
                    </span>Work with color & Gradients & Grids
                </p>
                <p className='flex m-0 items-center fw-semibold my-3 gap-2'>
                    <span className=' p-2 makeshadow  bg-blue-500 rounded-full text-white  -rotate-90'><ArrowDown size={12} />
                    </span>All the useful shortcuts
                </p>
                <p className='flex m-0 items-center fw-semibold my-3 gap-2'>
                    <span className=' p-2 makeshadow  bg-blue-500 rounded-full text-white  -rotate-90'><ArrowDown size={12} />
                    </span>Be able to create Flyers, Brochures, Advertisements </p>
                <p className='flex m-0 items-center fw-semibold my-3 gap-2'>
                    <span className=' p-2 makeshadow  bg-blue-500 rounded-full text-white  -rotate-90'><ArrowDown size={12} />
                    </span>How to work with Images & Text</p>
            </div>
            <p className=' text-slate-500 '>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non illo similique, illum laboriosam dicta earum consequatur consequuntur possimus quod, repudiandae facilis! Asperiores repellat harum nihil facilis porro error itaque voluptatibus fuga dolor. Mollitia vitae incidunt ratione ullam voluptatem quos amet possimus, officia consequatur expedita nulla doloribus esse veritatis illum quis? Officia sed dolor ab earum. Excepturi laborum rerum distinctio quas velit nostrum explicabo quisquam! Dolorem impedit doloribus molestiae recusandae,
                provident consequuntur nostrum a id cum illum laborum error saepe voluptatibus.
            </p>
            <hr />
            <div className='flex flex-wrap gap-3 justify-between items-center'>
                <div className='flex gap-1 h-fit w-fit max-w-1/2'>
                    Tags :
                    {
                        ['Software Development', 'Cleaning'].map((x) => (
                            <p className='mb-0 p-1 px-3 rounded-full text-white bg-blue-600 text-sm w-fit '> {x} </p>
                        ))
                    }

                </div>
                <div className='w-fit flex gap-2 items-center'>
                    <p className='fw-semibold mb-0  ' >Share :</p>
                    <img src={facebookShare} className='h-fit cursor-pointer ' alt="Facebook Share" />
                    <img src={twitterShare} className='h-fit cursor-pointer ' alt="Twitter" />
                    <img src={whatsappShare} className='h-fit cursor-pointer ' alt="Whatsapp" />
                    <img src={instagramShare} className='h-fit cursor-pointer ' alt="INstagram    " />
                </div>
            </div>
            <hr />
            {/* COmmets */}
            <div style={{ backgroundColor: 'rgb(247,247,250)' }}
                className='p-4 rounded  flex flex-col flex-sm-row sm:items-center gap-3'>
                <img src={require('../../Assest/comment1.png')} className='w-[35px] inter h-fit sm:w-[100px] ' alt="comment" />
                <div className=''>
                    <p className='mb-0'>Author</p>
                    <p className='text-xl fw-semibold mb-0'>Brooklyn Simmons </p>
                    <p className='text-slate-600 mt-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit porro amet quaerat fugit
                        atque modi reiciendis accusantium vitae repudiandae eligendi!</p>
                </div>
            </div>
            <p className='fw-semibold poppins text-2xl my-2 ' >02 Comments </p>
            {/* ONe more Comment */}
            <div
                className='p-4 rounded  flex flex-col flex-sm-row sm:items-center gap-3'>
                <img src={require('../../Assest/comment2.png')} className='w-[35px] inter h-fit sm:w-[100px] ' alt="comment" />
                <div className=''>
                    <p className='mb-0 flex'>Author <span className='ms-auto text-xs  text-slate-600'>20 July,2024 </span></p>
                    <p className='text-xl fw-semibold mb-0'>Brooklyn Simmons </p>
                    <p className='text-slate-600 mt-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit porro amet quaerat fugit
                        atque modi reiciendis accusantium vitae repudiandae eligendi!</p>
                    <button style={{ backgroundColor: 'rgb(247,247,250)' }} className='p-1 px-3 rounded uppercase text-sm text-violet-700 '>reply </button>
                </div>
            </div>
            <hr />
            <div
                className='p-4 rounded flex flex-col flex-sm-row sm:items-center gap-3'>
                <img src={require('../../Assest/comment2.png')} className='w-[35px] inter h-fit sm:w-[100px] ' alt="comment" />
                <div className=''>
                    <p className='mb-0 flex'>Author <span className='ms-auto text-xs  text-slate-600'>20 July,2024 </span></p>
                    <p className='text-xl fw-semibold mb-0'>Brooklyn Simmons </p>
                    <p className='text-slate-600 mt-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit porro amet quaerat fugit
                        atque modi reiciendis accusantium vitae repudiandae eligendi!</p>
                    <button style={{ backgroundColor: 'rgb(247,247,250)' }} className='p-1 px-3 rounded uppercase text-sm text-violet-700 '>reply </button>
                </div>
            </div>

        </div>
    )
}

export default BlogDetails