import React from 'react'
import SearchIcon from '../svg/SearchIcon'
import CalenderIcon from '../svg/CalenderIcon'
import CommentIcon from '../svg/CommentIcon'
import SubjectSlides from '../Component/Blog/SubjectSlides'
import TreandyPost from '../Component/Blog/TreandyPost'
import Allpost from '../Component/Blog/Allpost'
import LatestPost from '../Component/Blog/LatestPost'
import PopularPost from '../Component/Blog/Popularpost'

const Blog = () => {
    return (
        <div className='container mx-auto row py-5 col-xl-10'>
            {/* All Contents */}
            <div className='col-lg-8 col-xl-9 h-[125vh]  scrollbar overflow-y-scroll'>
                {/* Section 1 */}
                <div className='relative inter rounded min-h-[50vh] sm:min-h-0 '>
                    <img src={require('../Assest/blog5.png')} className='w-full ' alt="Blog Imaeg" />
                    <div className='w-4/5 absolute bottom-5 text-xs left-5 rounded bg-slate-50 bg-opacity-70 p-2 px-3 '>
                        <p className=' text-xl lg:text-2xl fw-medium'>Why I stopped Using Multiple Monitor</p>
                        <div className='flex gap-3 items-center'>
                            <p className='flex gap-2 items-center'><span className='text-blue-600'> <CalenderIcon /> </span> 20 July, 2024  </p>
                            <p className='flex gap-2 items-center'><span className='text-blue-600'> <CommentIcon /> </span> 05 Comments  </p>

                        </div>
                        <p className='text-sm'>A Single Monitor Manifesto — Many developers believe multiple monitors improve productivity. Studies have proven
                            it, right? Well, keep in mind, many of those studies are commissioned from monitor manufacturers like</p>

                    </div>
                </div>
                {/* Subject slides */}
                <SubjectSlides />
                <TreandyPost />
                <Allpost />
            </div>
            {/* Filter sticky */}
            <section className='col-lg-4 col-xl-3 '>
                <div className=' rounded w-full bg-slate-50 p-3'>
                    <div className='rounded flex w-full border-1 bg-sky-200 bg-opacity-30'>
                        <input type="text" className='w-full outline-none border-0 bg-transparent h-full rounded p-1' placeholder='Search' />
                        <button className='p-[10px] rounded bg-blue-500 text-white'> <SearchIcon /> </button>
                    </div>
                </div>
                <LatestPost />
                <PopularPost />
                <div style={{ backgroundColor: 'rgb(247,247,249)' }} className='my-2 p-3 rounded '>
                    <p className='text-black text-lg fw-semibold '>Popular Tag</p>
                    <div className='flex gap-2 flex-wrap'>

                        {
                            ['Education', 'Training', 'Online', 'Learn', 'Course', 'LMS'].map((x) => {
                                return (
                                    <p className='p-1 mb-0 px-2 text-violet-500 bg-white'>{x} </p>
                                )
                            })
                        }

                    </div>
                </div>





            </section>


        </div>
    )
}

export default Blog