import React, { useState } from 'react'
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import { darklogo } from '../../Utils/Image'
import SearchIcon from '../../svg/SearchIcon'
import { useNavigate } from 'react-router-dom'
import ArrowDown from '../../svg/ArrowDown'
import NavDrop from './NavDrop'
const NavBarCom = () => {
    let navigate = useNavigate()
    let [showDrop, setDrop] = useState(false)
    return (
        <div className='min-h-[10vh] z-[100] sticky top-0 bgclr2 w-full '>
            <Navbar key='lg' expand='lg' className="min-h-[10vh] container mx-auto items-center inter">
                <Container fluid className=' '>
                    <div className='flex items-center '>
                        <Navbar.Brand href="/">
                            <img src={darklogo} width={140} alt="Logo" />
                        </Navbar.Brand>
                        <div className='relative  mx-2 mx-xl-4 mt-2'>
                            <button  onClick={() => setDrop(!showDrop)}
                                className='d-none d-lg-flex grd text-white  flex h-fit items-center gap-2 rounded border-2 bclr2 p-[7px] px-3 '>
                                Courses   <ArrowDown />
                            </button>
                            {
                                showDrop && <NavDrop setshow={setDrop} />
                            }
                        </div>
                    </div>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
                    <Navbar.Offcanvas className=''
                        id={`offcanvasNavbar-expand-lg`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                        placement="start">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                                <img src={darklogo} width={120} alt="Logo" />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="lg:justify-between xl:w-[60vw] text-black fw-semibold text-sm uppercase ms-auto pe-3">
                                {/* <Nav.Link onClick={()=>navigate('/')}>Home</Nav.Link> */}

                                <Nav.Link href="/whysla" className='mt-2' >Why SLA?</Nav.Link>
                                <Nav.Link href="/allCourse" className='mt-2' >Courses</Nav.Link>
                                <Nav.Link href="/blogs" className='mt-2' >Blogs</Nav.Link>
                                <Nav.Link href="" className='mt-2' >Corporate Training</Nav.Link>

                                <Nav.Link onClick={() => navigate('/contact')} className='d-lg-none mt-2' href="">Contact</Nav.Link>
                                <button onClick={() => navigate('/contact')} className='bclr2 d-none mt-2 d-lg-block w-fit mx-3 p-2 px-3 rounded fw-semibold border-2 border-sky-700 text-sm  uppercase'>
                                    Contact us
                                </button>
                                <button className='mx-3 mt-2 d-none d-lg-block'>
                                    <SearchIcon />
                                </button>
                            </Nav>

                        </Offcanvas.Body>
                    </Navbar.Offcanvas>


                </Container>
            </Navbar>


        </div>
    )
}

export default NavBarCom