import React from 'react'
import { homeContact } from '../../Utils/Image'

const Contactcom = () => {
    return (
        <div className='min-h-[70vh] flex xl:min-h-[100vh] '>
            <div className='row m-auto items-center container'>
                <div className='col-lg-6'>
                    <img src={homeContact} alt="Home contact" />
                </div>
                <div className='col-lg-6 ubuntu'>
                    <p className='ubuntu uppercase text-sky-700'>Contact us </p>
                    <p className='ubuntu text-4xl fw-bold'>Let's Collaborate Now! </p>
                    <div className='my-2'>
                        <p className=' fw-semibold text-xl '>Full Name</p>
                        <input type="text" placeholder='Input Your Name' className={` rounded-3xl outline-none w-full block bgclr3 p-[22px] ps-[30px] my-2  `} />
                    </div>
                    <div className='my-2'>
                        <p className=' fw-semibold text-xl '>Email </p>
                        <input type="text" placeholder='Input Your Name' className={` rounded-3xl outline-none w-full block bgclr3 p-[22px] ps-[30px] my-2  `} />
                    </div>
                    <div className='my-2'>
                        <p className=' fw-semibold text-xl '>Message </p>
                        <textarea rows={4} type="text" placeholder='Input Your Name' className={` rounded-3xl outline-none w-full block bgclr3 p-[22px] ps-[30px] my-2  `} />
                    </div>
                    <button className='p-2 px-3 bclr1 grd2 uppercase rounded text-white my-3'>Contact us </button>

                </div>

            </div>
        </div>
    )
}

export default Contactcom